// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@nuxt/postcss8/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/icons/interface-search.svg?img");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*purgecss start ignore*/\n.sky__search-input[data-v-b474e8a4]{\n  width:100%;\n  border-radius:50px;\n  position:absolute;\n  left:0;\n  right:0;\n  bottom:0;\n  top:0;\n  height:45px\n}\n.sky__search-input.small[data-v-b474e8a4]{\n  height:40px\n}\n.sky__search-wrapper[data-v-b474e8a4]{\n  position:relative;\n  height:45px\n}\n.sky__search-wrapper.small[data-v-b474e8a4]{\n  height:40px\n}\n.sky__search-wrapper[data-v-b474e8a4]:after{\n  content:\"\";\n  display:block;\n  position:absolute;\n  right:7px;\n  top:0;\n  width:47px;\n  height:47px;\n  background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size:cover;\n  z-index:50\n}\n.sky__search-wrapper.small[data-v-b474e8a4]:after{\n  transform:scale(.8);\n  top:-4px\n}\n\n/*purgecss end ignore*/", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
