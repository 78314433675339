import Vue from 'vue'
import IconNavArrowRight from '../assets/icons/nav-arrow-right.svg'
import IconNavCross from '../assets/icons/nav-cross.svg'
import IconBurger from '../assets/icons/interface-burger.svg'
import IconSearch from '../assets/icons/interface-search.svg'
import IconLogo from '../assets/icons/logo.svg'
import IconEcoResponsability from '../assets/icons/custom-eco-responsable.svg'
import IconStores from '../assets/icons/custom-magasins.svg'
import IconProjects from '../assets/icons/custom-mes-projets.svg'
import IconAccount from '../assets/icons/interface-user.svg'
import IconCart from '../assets/icons/interface-shopping-cart.svg'
import IconCartAdd from '../assets/icons/interface-shopping-cart-add.svg'
import IconStarFull from '../assets/icons/nav-star.svg'
import IconStarEmpty from '../assets/icons/nav-star-empty.svg'
import IconWishlistEmpty from '../assets/icons/interface-wishlist-empty-dark.svg'
import IconInsuranceCup from '../assets/icons/insurance-1.svg'
import IconInsuranceSecure from '../assets/icons/insurance-5.svg'
import IconInsuranceThumb from '../assets/icons/insurance-3.svg'
import IconFrance from '../assets/icons/custom-fr.svg'
import IconUk from '../assets/icons/custom-en.svg'
import IconFacebook from '../assets/icons/network-facebook.svg'
import IconInstagram from '../assets/icons/network-instagram.svg'
import IconTwitter from '../assets/icons/network-twitter.svg'
import IconPinterest from '../assets/icons/network-pinterest.svg'
import IconYoutube from '../assets/icons/network-youtube.svg'
import IconNavFilter from '../assets/icons/nav-filter.svg'
import IconNavSelect from '../assets/icons/nav-select.svg'
import IconShipping from '../assets/icons/custom-livraison.svg'
import IconHistory from '../assets/icons/account-history.svg'
import IconPayment from '../assets/icons/account-credit-card.svg'
import IconSelectDown from '../assets/icons/nav-select-down.svg'

Vue.component('IconNavArrowRight', IconNavArrowRight)
Vue.component('IconNavCross', IconNavCross)
Vue.component('IconBurger', IconBurger)
Vue.component('IconSearch', IconSearch)
Vue.component('IconLogo', IconLogo)
Vue.component('IconEcoResponsability', IconEcoResponsability)
Vue.component('IconStores', IconStores)
Vue.component('IconProjects', IconProjects)
Vue.component('IconAccount', IconAccount)
Vue.component('IconCart', IconCart)
Vue.component('IconStarFull', IconStarFull)
Vue.component('IconStarEmpty', IconStarEmpty)
Vue.component('IconCartAdd', IconCartAdd)
Vue.component('IconWishlistEmpty', IconWishlistEmpty)
Vue.component('IconInsuranceCup', IconInsuranceCup)
Vue.component('IconInsuranceSecure', IconInsuranceSecure)
Vue.component('IconInsuranceThumb', IconInsuranceThumb)
Vue.component('IconFrance', IconFrance)
Vue.component('IconUk', IconUk)
Vue.component('IconFacebook', IconFacebook)
Vue.component('IconInstagram', IconInstagram)
Vue.component('IconTwitter', IconTwitter)
Vue.component('IconPinterest', IconPinterest)
Vue.component('IconYoutube', IconYoutube)
Vue.component('IconNavFilter', IconNavFilter)
Vue.component('IconNavCheck', IconNavSelect)
Vue.component('IconShipping', IconShipping)
Vue.component('IconHistory', IconHistory)
Vue.component('IconPayment', IconPayment)
Vue.component('IconSelectDown', IconSelectDown)
