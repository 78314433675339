export default {
  state () {
    return {
      scroll: 0,
      width: 0,
      height: 0,
      ordering: false
    }
  },
  mutations: {
    SET_SCROLL (state, value) {
      state.scroll = value
    },
    SET_SCREEN_SIZE (state, { width, height }) {
      state.width = width
      state.height = height
    },
    SET_ORDERING (state, ordering) {
      state.ordering = ordering
    }
  },
  actions: {
    monitorScreen ({ commit }) {
      commit('SET_SCREEN_SIZE', {
        width: window.innerWidth,
        height: window.innerHeight
      })

      window.addEventListener('scroll', () => {
        commit('SET_SCROLL', window.pageYOffset)
      })

      window.addEventListener('resize', () => {
        commit('SET_SCREEN_SIZE', {
          width: window.innerWidth,
          height: window.innerHeight
        })
      })
    }
  },
  getters: {
    isScrolled (state) {
      return state.scroll > 100
    }
  }
}
