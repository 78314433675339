<template>
  <div>
    <search-bar
      v-model="search"
      class="sky__search-bar"
      :class="{
        'is-scrolled': small
      }"
      :small="small"
      @input="debouncedSearchFn"
      @focus="active = true"
      @keyup.esc="(e) => {
        e.target.blur()
        active = false
      }"
      @keyup.enter="(e) => {
        e.target.blur()
        active = false
        $router.push('/recherche?search=' + search)
      }"
    />
    <transition name="bounce">
      <div v-show="active && search && result" class="container sky__search-results">
        <div v-if="result" class="flex-1 relative mx-2">
          <div class="font-funny text-3xl">
            Produits
          </div>
          <div class="sky__search-products-results">
            <nuxt-link
              v-for="product in result[0].hits"
              :key="product.sku"
              class="mb-2 block"
              :to="$helpers.makeUri(product.path)"
            >
              <product-card
                :product="product"
                class="bg-white hover:bg-gray-100 pr-2"
              />
            </nuxt-link>
          </div>
        </div>
        <div v-if="result" class="flex-1 px-2 relative">
          <div v-if="result[1].hits.length" class="font-funny text-3xl">
            Catégories
          </div>
          <div class="sky__search-products-results">
            <nuxt-link
              v-for="category in result[1].hits"
              :key="category.id"
              class="mb-2 block"
              :to="$helpers.makeUri(category.path, 'category')"
            >
              <div
                class="w-full mb-3 flex justify-center items-center font-funny text-3xl"
                :style="{
                  height: '100px',
                  backgroundImage: `url(${category.cover ? category.cover + '?resize=true&mwidth=400' : require('../../../assets/fixtures/categorie_guinguette.jpg')})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center top',
                }"
                :class="{
                  'text-white': typeof category.dark_background === 'undefined' ? true : category.dark_background
                }"
              >
                {{ category.label }}
              </div>
            </nuxt-link>
          </div>
        </div>
        <div class="flex-1" />
      </div>
    </transition>
    <transition name="overlay-transition">
      <div v-show="active" class="overlay" @click="active = false" />
    </transition>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite'
import debounce from 'lodash.debounce'
import ProductSearchResult from './ProductSearchResult'
import SearchBar from './SearchBar'

const client = algoliasearch('GS18F5F1NL', '4667e1a6962d23cb13aab5af604ed12c')

export default {
  components: {
    SearchBar,
    ProductCard: ProductSearchResult
  },
  props: {
    small: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: false,
      result: null,
      search: '',
      queued: false,
      searching: false,
      debouncedSearchFn: debounce(async () => {
        if (this.searching) {
          this.queued = true
          return
        }
        this.searching = true
        const response = await client.multipleQueries([
          {
            indexName: 'products',
            query: this.search,
            params: {
              hitsPerPage: 10
            }
          },
          {
            indexName: 'categories',
            query: this.search,
            params: {
              hitsPerPage: 10
            }
          }
        ])
        this.result = response.results
        this.searching = false
        if (this.queued) {
          this.queued = false
          await this.makeSearch()
        }
      }, 200)
    }
  },
  watch: {
    '$route.fullPath' (to, from) {
      this.active = false
    }
  },
  mounted () {
    // this.makeSearch()
  }
}
</script>

<style lang="scss" scoped>
  .sky__search-bar {
    @apply relative z-30;
    height: 50px;
    &.is-scrolled {
      height: 40px;
    }
  }
  .overlay {
    @apply fixed left-0 right-0 bottom-0 top-0 z-10;
    opacity: 1;
    background: rgba(black, 0.8);
  }

  .overlay-transition-enter-active, .overlay-transition-leave-active {
    transition: all 0.3s;
  }
  .overlay-transition-enter, .overlay-transition-leave-to {
    opacity: 0;
  }

  .sky__search-results {
    @apply flex fixed left-0 right-0 z-20 mx-auto bg-white rounded p-5;
    top: 150px;
    height: calc(100vh - 180px);
    ::-webkit-scrollbar {
      width: 0;
    }
  }

  .sky__search-products-results {
    @apply absolute left-0 right-0 bottom-0 overflow-auto;
    top: 45px;
  }

  .sky__search-suggestions-results {
    @apply absolute left-0 right-0 bottom-0 overflow-auto px-4;
    top: 45px;
  }

  .bounce-enter-active {
    animation: bounce-in 0.3s;
  }
  .bounce-leave-active {
    animation: bounce-in .2s reverse;
  }
  @keyframes bounce-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
