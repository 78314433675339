var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('search-bar',{staticClass:"sky__search-bar",class:{
      'is-scrolled': _vm.small
    },attrs:{"small":_vm.small},on:{"input":_vm.debouncedSearchFn,"focus":function($event){_vm.active = true},"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return (function (e) {
      e.target.blur()
      _vm.active = false
    }).apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (e) {
      e.target.blur()
      _vm.active = false
      _vm.$router.push('/recherche?search=' + _vm.search)
    }).apply(null, arguments)}]},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),_c('transition',{attrs:{"name":"bounce"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active && _vm.search && _vm.result),expression:"active && search && result"}],staticClass:"container sky__search-results"},[(_vm.result)?_c('div',{staticClass:"flex-1 relative mx-2"},[_c('div',{staticClass:"font-funny text-3xl"},[_vm._v("\n          Produits\n        ")]),_vm._v(" "),_c('div',{staticClass:"sky__search-products-results"},_vm._l((_vm.result[0].hits),function(product){return _c('nuxt-link',{key:product.sku,staticClass:"mb-2 block",attrs:{"to":_vm.$helpers.makeUri(product.path)}},[_c('product-card',{staticClass:"bg-white hover:bg-gray-100 pr-2",attrs:{"product":product}})],1)}),1)]):_vm._e(),_vm._v(" "),(_vm.result)?_c('div',{staticClass:"flex-1 px-2 relative"},[(_vm.result[1].hits.length)?_c('div',{staticClass:"font-funny text-3xl"},[_vm._v("\n          Catégories\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"sky__search-products-results"},_vm._l((_vm.result[1].hits),function(category){return _c('nuxt-link',{key:category.id,staticClass:"mb-2 block",attrs:{"to":_vm.$helpers.makeUri(category.path, 'category')}},[_c('div',{staticClass:"w-full mb-3 flex justify-center items-center font-funny text-3xl",class:{
                'text-white': typeof category.dark_background === 'undefined' ? true : category.dark_background
              },style:({
                height: '100px',
                backgroundImage: ("url(" + (category.cover ? category.cover + '?resize=true&mwidth=400' : require('../../../assets/fixtures/categorie_guinguette.jpg')) + ")"),
                backgroundSize: 'cover',
                backgroundPosition: 'center top',
              })},[_vm._v("\n              "+_vm._s(category.label)+"\n            ")])])}),1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-1"})])]),_vm._v(" "),_c('transition',{attrs:{"name":"overlay-transition"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.active),expression:"active"}],staticClass:"overlay",on:{"click":function($event){_vm.active = false}}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }