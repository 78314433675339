export default {
  items: [
    {
      text: 'Nouveautés',
      type: 'link',
      to: '/nouveautes/',
      picto: require('../assets/fixtures/menu-mobile/nouveautes.png')
    },
    {
      text: 'Nos Produits',
      type: 'nested',
      picto: require('../assets/fixtures/menu-mobile/nos-produits.png'),
      items: [
        {
          text: 'Guirlande Lumineuse',
          type: 'nested',
          picto: require('../assets/fixtures/menu-mobile/guirlande-lumineuse.png'),
          // allProducts: '/guirlande-lumineuse/',
          to: '/guirlande-lumineuse/',
          sliderPicture: require('../assets/menu/Guirlande_lumineuse.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_guirlande_lumineuse.jpg')})`,
          items: [
            {
              text: 'Guirlande Guinguette',
              to: '/guirlande-lumineuse/guirlandes-guinguette/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Guirlande_guinguette.jpg'),
              items: [
                {
                  text: 'Guirlande Guinguette 10m',
                  to: '/guirlande-lumineuse/guirlandes-guinguette/caracteristiques/longueur-10m/'
                },
                {
                  text: 'Guirlande Guinguette 20m',
                  to: '/guirlande-lumineuse/guirlandes-guinguette/caracteristiques/longueur-20m/'
                },
                {
                  text: 'Guirlande Guinguette 50m',
                  to: '/guirlande-lumineuse/guirlandes-guinguette/caracteristiques/longueur-50m/'
                },
                {
                  text: 'Guirlande Guinguette Professionnelle',
                  to: '/guirlande-lumineuse/guirlandes-guinguette/guirlande-guinguette-professionnelle/'
                },
                {
                  text: 'Guirlande Guinguette Solaire',
                  to: '/guirlande-lumineuse/guirlandes-guinguette/caracteristiques/type_alimentation-solaire/'
                },
                {
                  text: 'Ampoule Guirlande Guinguette',
                  to: '/guirlande-lumineuse/guirlandes-guinguette/caracteristiques/type_d_accessoire_guinguette-ampoule_seule/'
                }
              ]
            },
            {
              text: 'Guirlande de Noel',
              to: '/guirlande-lumineuse/guirlande-de-noel/',
              type: 'link',
              picto: require('../assets/menu-mobile/Guirlande_de_noel.jpg'),
              items: [
                {
                  text: 'Guirlande Lumineuse Sapin',
                  to: '/guirlande-lumineuse/guirlande-de-noel/caracteristiques/longueur-12m/'
                },
                {
                  text: 'Guirlande Exterieur Noel',
                  to: '/guirlande-lumineuse/guirlande-de-noel/caracteristiques/longueur-22m-34m-44m-56m-66m/'
                },
                {
                  text: 'Rideau Lumineux Noel',
                  to: '/guirlande-lumineuse/guirlande-de-noel/caracteristiques/longueur-3m-6m/'
                }
              ]
            },
            {
              text: 'Guirlande LED',
              to: '/guirlande-lumineuse/guirlandes-led/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Guirlande_led.jpg'),
              items: [
                {
                  text: 'Guirlande Micro LED',
                  to: '/guirlande-lumineuse/guirlande-micro-led/'
                },
                {
                  text: 'Guirlande Photo',
                  to: '/guirlande-lumineuse/guirlande-photo/'
                }
              ]
            },
            {
              text: 'Néon',
              to: '/decoration-lumineuse/neon/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Decoration_lumineuse.jpg'),
              items: [
                {
                  text: 'Néons Symboles',
                  to: '/decoration-lumineuse/neon/caracteristiques/type_de_motif-symbole/'
                },
                {
                  text: 'Néons Messages',
                  to: '/decoration-lumineuse/neon/caracteristiques/type_de_motif-message/'
                }
              ]
            },
            {
              text: 'Décoration Lumineuse',
              to: '/decoration-lumineuse/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Decoration_lumineuse.jpg'),
              items: [
                {
                  text: 'LightBox',
                  to: '/decoration-lumineuse/lightbox/'
                },
                {
                  text: 'Lettre Lumineuse',
                  to: '/decoration-lumineuse/lettres-lumineuses/'
                },
                {
                  text: 'Suspension Lumineuse',
                  to: '/decoration-lumineuse/suspensions-lumineuses/'
                },
                {
                  text: 'Cloches en Verre LED',
                  to: '/decoration-lumineuse/cloche-verre-led/'
                },
                {
                  text: 'LED pour Lampions',
                  to: '/decoration-lumineuse/led-pour-lampion/'
                },
                {
                  text: 'Etoiles et Coeur Lumineux',
                  to: '/decoration-lumineuse/etoiles-et-coeur-lumineux/'
                }
              ]
            }
          ]
        },
        {
          text: 'Luminaire Exterieur',
          type: 'nested',
          picto: require('../assets/fixtures/menu-mobile/guirlande-lumineuse.png'),
          to: '/luminaire-exterieur/',
          sliderPicture: require('../assets/menu/Guirlande_lumineuse.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_guirlande_lumineuse.jpg')})`,
          items: [
            {
              text: 'Lampe solaire exterieur',
              to: '/luminaire-exterieur/lampe-solaire-exterieur/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Guirlande_guinguette.jpg'),
              items: [
                {
                  text: 'Spot Solaire Extérieur',
                  to: '/luminaire-exterieur/lampe-solaire-exterieur/caracteristiques/type_de_luminaire-spot_led_exterieur/'
                },
                {
                  text: 'Projecteur Solaire',
                  to: '/luminaire-exterieur/lampe-solaire-exterieur/caracteristiques/type_de_luminaire-projecteur_led_exterieur/'
                },
                {
                  text: 'Eclairage Exterieur avec Detecteur',
                  to: '/luminaire-exterieur/lampe-solaire-exterieur/caracteristiques/type_de_detecteur-detecteur_de_mouvement/'
                }
                
              ]
            }
          ]
        },
        {
          text: 'Mobilier Extérieur',
          type: 'nested',
          picto: require('../assets/menu-mobile/Outdoor.jpg'),
          sliderPicture: require('../assets/menu/Outdoor.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_outdoor.jpg')})`,
          items: [
            {
              text: 'Mobilier de Cérémonie',
              type: 'nested',
              to: '/mobilier-de-ceremonie/',
              picto: require('../assets/menu-mobile/Mobilier_de_ceremonie.jpg'),
              items: [
                {
                  text: 'Tables et Chaises Pliantes',
                  to: '/mobilier-de-ceremonie/table-pliante/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Tonnelle',
                  to: '/mobilier-de-ceremonie/tonnelle/',
                  picto: require('../assets/menu-mobile/Guirlande_papier.jpg')
                },
                {
                  text: 'Barnum',
                  to: '/mobilier-de-ceremonie/barnum/',
                  picto: require('../assets/menu-mobile/Guirlande_papier.jpg')
                }
              ]
            },
            {
              text: 'Arches de Mariage',
              type: 'nested',
              to: '/mobilier-de-ceremonie/arche-mariage/',
              picto: require('../assets/menu-mobile/Mobilier_de_ceremonie.jpg'),
              items: [
                {
                  text: 'Arche Hexagonale',
                  to: '/mobilier-de-ceremonie/arche-mariage/caracteristiques/forme-hexagonale/',
                  picto: require('../assets/menu-mobile/Guirlande_tassel.jpg')
                },
                {
                  text: 'Arche Rectangulaire',
                  to: '/mobilier-de-ceremonie/arche-mariage/caracteristiques/forme-rectangulaire/',
                  picto: require('../assets/menu-mobile/Guirlande_tassel.jpg')
                },
                {
                  text: 'Arche Triangulaire',
                  to: '/mobilier-de-ceremonie/arche-mariage/caracteristiques/forme-triangulaire/',
                  picto: require('../assets/menu-mobile/Guirlande_tassel.jpg')
                }
              ]
            },
            {
              text: 'Mobilier de Jardin',
              type: 'nested',
              to: '/mobilier-de-jardin/',
              picto: require('../assets/menu-mobile/Mobilier_de_jardin.jpg'),
              items: [
                {
                  text: 'Salon de Jardin',
                  to: '/mobilier-de-jardin/salon-de-jardin/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Table de Jardin',
                  to: '/mobilier-de-jardin/table-de-jardin/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Parasol',
                  to: '/mobilier-de-jardin/parasol/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Bain de Soleil',
                  to: '/mobilier-de-jardin/bain-de-soleil/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Hamac',
                  to: '/mobilier-de-jardin/hamac/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Coffre de Jardin',
                  to: '/mobilier-de-jardin/coffre-de-jardin/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Housse de Protection',
                  to: '/mobilier-de-jardin/housse/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Chariot de Jardin',
                  to: '/mobilier-de-jardin/chariot-de-jardin/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                }
              ]
            }
          ]
        },
        {
          text: 'Lanternes',
          type: 'nested',
          picto: require('../assets/fixtures/menu-mobile/lanternes.png'),
          sliderBackgroundImage: `url(${require('../assets/fixtures/bg-seo-categorie.jpg')})`,
          sliderPicture: require('../assets/fixtures/lanternes-volantes_photos_v2_x2.png'),
          items: [
            {
              text: 'Lanterne Volante',
              type: 'nested',
              to: '/lanterne-volante/',
              picto: require('../assets/menu-mobile/Lanterne_volante.jpg'),
              items: [
                {
                  text: 'Lanterne Céleste',
                  to: '/lanterne-volante/lanternes-celestes/'
                },
                {
                  text: 'Lanterne Thailandaise',
                  to: '/lanterne-volante/lanternes-thai/'
                },
                {
                  text: 'Lanterne Chinoise',
                  to: '/lanterne-volante/lanternes-chinoises/'
                },
                {
                  text: 'Lanternes Volantes Spéciales',
                  to: '/lanterne-volante/lanternes-speciales/'
                },
                {
                  text: 'Packs de Lanternes Volantes',
                  to: '/lanterne-volante/packs-speciaux/'
                }
              ]
            },
            {
              text: 'Lanterne Flottante',
              type: 'nested',
              to: '/lanternes/lanterne-flottante/',
              picto: require('../assets/menu-mobile/Lanterne_flottante.jpg'),
              items: [
                {
                  text: 'Lanterne flottante papier',
                  to: '/lanternes/lanterne-flottante/caracteristiques/type_de_lanterne_flottante-en_papier/'
                },
                {
                  text: 'Lanterne flottante LED',
                  to: '/lanternes/lanterne-flottante/caracteristiques/type_de_lanterne_flottante-led/'
                }
              ]
            },
            {
              text: 'Lanterne Extérieure',
              type: 'nested',
              to: '/lanternes/lanterne-exterieur/',
              picto: require('../assets/menu-mobile/Lanterne_exterieure.jpg'),
              items: [
                {
                  text: 'Photophore',
                  to: '/lanternes/photophore/'
                },
                {
                  text: 'Lanterne de Table',
                  to: '/lanternes/lanterne-de-table/'
                },
                {
                  text: 'Lanterne de Jardin',
                  to: '/lanternes/lanterne-de-jardin/'
                }
              ]
            }
          ]
        },
        {
          text: 'Lampion Boule Papier',
          type: 'nested',
          picto: require('../assets/fixtures/menu-mobile/lampion-boule-papier.png'),
          sliderPicture: require('../assets/menu/Lampion_boule_papier.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_lampion_boule_papier.jpg')})`,
          items: [
            {
              text: 'Boule Papier',
              type: 'nested',
              to: '/boule-papier/',
              picto: require('../assets/menu-mobile/Boule_papier.jpg'),
              items: [
                {
                  text: 'Boule Japonaise',
                  to: '/boule-papier/boules-japonaises/'
                },
                {
                  text: 'Boules Japonaises Spéciales',
                  to: '/boule-papier/boules-japonaises-speciales/'
                },
                {
                  text: 'Boule Chinoise Alvéolée',
                  to: '/boule-papier/boules-chinoises/'
                },
                {
                  text: 'LED à suspendre',
                  to: '/decoration-lumineuse/led-pour-lampion/'
                }
              ]
            },
            {
              text: 'Lampion',
              type: 'nested',
              to: '/boule-papier/lampion-papier/',
              picto: require('../assets/menu-mobile/Lampion_boule_papier.jpg'),
              items: [
                {
                  text: 'Lampion Papier',
                  to: '/boule-papier/lampion-papier/'
                },
                {
                  text: 'Lampion LED',
                  to: '/boule-papier/lampions-led/'
                },
                {
                  text: 'LED pour lampion',
                  to: '/decoration-lumineuse/led-pour-lampion/'
                },
                {
                  text: 'Etoiles en carton',
                  to: '/boule-papier/etoiles-en-carton/'
                }
              ]
            },
            {
              text: 'Fleurs et Pompons',
              type: 'nested',
              to: '/fleurs-artificielles/fleur-en-papier/',
              picto: require('../assets/menu-mobile/Fleurs_et_pompons.jpg'),
              items: [
                {
                  text: 'Fleur en Papier',
                  to: '/fleurs-artificielles/fleur-en-papier/'
                },
                {
                  text: 'Pompon Papier',
                  to: '/boule-papier/pompons-papier/'
                },
                {
                  text: 'Rosace en Papier',
                  to: '/boule-papier/rosace-en-papier/'
                }
              ]
            },
            {
              text: 'Guirlande',
              type: 'nested',
              to: '/guirlande/',
              picto: require('../assets/menu-mobile/Guirlande.jpg'),
              items: [
                {
                  text: 'Guirlande Papier',
                  to: '/guirlande/guirlande-papier/'
                },
                {
                  text: 'Fanions',
                  to: '/guirlande/fanions/'
                },
                {
                  text: 'Guirlande Tassel',
                  to: '/guirlande/guirlande-tassels/'
                },
                {
                  text: 'Guirlande Lettre',
                  to: '/guirlande/guirlande-lettre/'
                }
              ]
            }
          ]
        },
        {
          text: 'Bougies',
          type: 'nested',
          picto: require('../assets/fixtures/menu-mobile/bougies.png'),
          sliderPicture: require('../assets/menu/Bougie_led.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_bougies.jpg')})`,
          items: [
            {
              text: 'Bougies LED',
              to: '/bougies/bougie-led/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Bougies_led.jpg'),
              items: [
                {
                  text: 'Pilier LED',
                  to: '/bougies/bougie-led/caracteristiques/type_de_bougies-pilier/'
                },
                {
                  text: 'Chandelle LED',
                  to: '/bougies/chandelles-led/'
                },
                {
                  text: 'Bougie Chauffe Plat LED',
                  to: '/bougies/bougies-chauffe-plat/'
                }
              ]
            },
            {
              text: 'Bougie de décoration',
              to: '/bougies/bougie-de-decoration/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Bougie_de_decoration.jpg'),
              items: [
                {
                  text: 'Bougies Piliers',
                  to: '/bougies/bougie-de-decoration/caracteristiques/type_de_bougies-pilier/'
                },
                {
                  text: 'Bougies Rondes',
                  to: '/bougies/bougie-de-decoration/caracteristiques/type_de_bougies-bougies_rondes/'
                },
                {
                  text: 'Chandelles',
                  to: '/bougies/bougie-de-decoration/caracteristiques/type_de_bougies-chandelles/'
                },
                {
                  text: 'Bougies Chauffe-Plat',
                  to: '/bougies/bougies-chauffe-plat/'
                },
                {
                  text: 'Cierges Magiques',
                  to: '/bougies/cierges-magiques/'
                }
              ]
            },
            {
              text: 'Bougies par couleur',
              to: '/bougies/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Bougies_par_couleur.jpg'),
              items: [
                {
                  text: 'Bougie Blanche',
                  to: '/bougies/caracteristiques/couleur-blanc/'
                },
                {
                  text: 'Bougie Noire',
                  to: '/bougies/caracteristiques/couleur-noir/'
                },
                {
                  text: 'Bougie Rose',
                  to: '/bougies/caracteristiques/couleur-rose_fuchsia-rose_pastel-rose_blush/'
                },
                {
                  text: 'Bougie Bleue',
                  to: '/bougies/caracteristiques/couleur-bleu_turquoise/'
                },
                {
                  text: 'Bougie Verte',
                  to: '/bougies/caracteristiques/couleur-vert_fonce/'
                },
                {
                  text: 'Bougie Grise',
                  to: '/bougies/caracteristiques/couleur-gris-argent/'
                }
              ]
            },
            {
              text: 'Bougie Mariage',
              to: '/decoration-mariage/deco-table-mariage/bougies-mariage/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Bougie_mariage.jpg'),
              items: [
                {
                  text: 'Piliers',
                  to: '/decoration-mariage/deco-table-mariage/bougies-mariage/caracteristiques/type_de_bougies-pilier/'
                },
                {
                  text: 'Bougies Rondes',
                  to: '/decoration-mariage/deco-table-mariage/bougies-mariage/caracteristiques/type_de_bougies-bougies_rondes/'
                },
                {
                  text: 'Chandelles',
                  to: '/decoration-mariage/deco-table-mariage/bougies-mariage/caracteristiques/type_de_bougies-chandelles/'
                },
                {
                  text: 'Chauffe-Plat',
                  to: '/decoration-mariage/deco-table-mariage/bougies-mariage/caracteristiques/type_de_bougies-bougies_chauffe_plat/'
                },
                {
                  text: 'Bougie LED Mariage',
                  to: '/bougies/bougie-led/'
                }
              ]
            }
            // {
            //   text: 'Chandelle LED',
            //   to: '/bougies/chandelles-led'
            // },
            // {
            //   text: 'Bougie Chauffe-Plat LED',
            //   to: '/bougies/bougies-chauffe-plat'
            // },
            // {
            //   text: 'Cierges Magiques',
            //   to: '/bougies/cierges-magiques'
            // }
          ],
          to: '/bougies/'
        },
        {
          text: 'Guirlande',
          type: 'nested',
          picto: require('../assets/fixtures/menu-mobile/guirlande.png'),
          // allProducts: '/guirlande/',
          to: '/guirlande/',
          sliderPicture: require('../assets/menu/Guirlande.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_guirlande.jpg')})`,
          items: [
            {
              text: 'Guirlande Tassels',
              to: '/guirlande/guirlande-tassels/',
              picto: require('../assets/menu-mobile/Guirlande_tassel.jpg')
            },
            {
              text: 'Guirlande Papier',
              to: '/guirlande/guirlande-papier/',
              picto: require('../assets/menu-mobile/Guirlande_papier.jpg')
            },
            {
              text: 'Guirlande Lettre',
              to: '/guirlande/guirlande-lettre/',
              picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
            },
            {
              text: 'Fanions',
              to: '/guirlande/fanions/',
              picto: require('../assets/menu-mobile/Fanions.jpg')
            }
          ]
        },
        {
          text: 'Ballons',
          type: 'nested',
          picto: require('../assets/fixtures/menu-mobile/ballons.png'),
          to: '/ballons/',
          sliderPicture: require('../assets/menu/Ballons.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_ballons.jpg')})`,
          items: [
            {
              text: 'Ballon Latex',
              type: 'nested',
              to: '/ballons/ballon-biodegradable/',
              picto: require('../assets/menu-mobile/Ballon_latex.jpg'),
              items: [
                {
                  text: 'Ballon Biodégradable',
                  to: '/ballons/ballon-biodegradable/'
                },
                {
                  text: 'Ballon Géant',
                  to: '/ballons/ballon-geant/'
                },
                {
                  text: 'Arche de Ballon',
                  to: '/ballons/arche-de-ballon/'
                },
                {
                  text: 'Ballon Confettis',
                  to: '/ballons/ballon-confettis/'
                },
                {
                  text: 'Ballons LED',
                  to: '/ballons/ballon-led/'
                },
                {
                  text: 'Ballon Marbré',
                  to: '/ballons/ballon-marbre/'
                }
              ]
            },
            {
              text: 'Ballon Aluminium',
              to: '/ballons/ballon-aluminium-mylar/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Ballon_alluminium.jpg'),
              items: [
                {
                  text: 'Ballon Lettre',
                  to: '/ballons/ballon-lettre/'
                },
                {
                  text: 'Ballon Chiffre',
                  to: '/ballons/ballon-chiffre/'
                },
                {
                  text: 'Ballon Motif',
                  to: '/ballons/ballon-aluminium-mylar/'
                }
              ]
            }
          ]
        },
        {
          text: 'Accessoire Déco',
          type: 'nested',
          picto: require('../assets/fixtures/menu-mobile/deco-mariage.png'),
          to: '/accessoire-deco/',
          // sliderPicture: require('../assets/menu/Accessoire_deco.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_accessoire_deco.jpg')})`,
          items: [
            {
              text: 'Photobooth',
              to: '/photobooth/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Photobooth.jpg'),
              items: [
                {
                  text: 'Accessoire Photobooth',
                  to: '/photobooth/'
                },
                {
                  text: 'Décoration Photocall',
                  to: '/photobooth/photocall/'
                }
              ]
            },
            {
              text: "Urne et Livre d'Or",
              type: 'nested',
              to: '/accessoire-deco/urne/',
              picto: require('../assets/menu-mobile/Urne_et_livre_d_or.jpg'),
              items: [
                {
                  text: 'Urne',
                  to: '/accessoire-deco/urne/'
                },
                {
                  text: "Livre d'Or",
                  to: '/livre-d-or/'
                }
              ]
            },
            {
              text: 'Lettre décoratives',
              to: '/decoration-lumineuse/lettres-lumineuses/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Lettre_decorative.jpg'),
              items: [
                {
                  text: 'Lettres Lumineuses',
                  to: '/decoration-lumineuse/lettres-lumineuses/'
                },
                {
                  text: 'Lettre Polystyrène',
                  to: '/accessoire-deco/lettre-polystyrene/'
                }
              ]
            },
            {
              text: 'Voilages',
              type: 'nested',
              to: '/tenture/',
              picto: require('../assets/menu-mobile/Voilages.jpg'),
              items: [
                {
                  text: 'Tenture',
                  to: '/tenture/'
                },
                {
                  text: 'Voilage',
                  to: '/recherche?search=voilage'
                }
              ]
            },
            {
              text: 'Pinata',
              to: '/accessoire-deco/pinata/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Pinata.jpg'),
              items: [
                {
                  text: 'Pinata Licorne',
                  to: '/accessoire-deco/pinata/caracteristiques/forme-licorne/'
                },
                {
                  text: 'Pinata Dinosaure',
                  to: '/accessoire-deco/pinata/caracteristiques/forme-dinosaure/'
                },
                {
                  text: 'Pinata Lama',
                  to: '/accessoire-deco/pinata/caracteristiques/forme-lama/'
                },
                {
                  text: 'Pinata Pirate',
                  to: '/accessoire-deco/pinata/caracteristiques/forme-pirate/'
                }
              ]
            },
            {
              text: 'Attrape Reve',
              to: '/attrape-reve/',
              type: 'link',
              picto: require('../assets/menu-mobile/Attrape_reve.jpg')
            },
            {
              text: 'Petite Déco',
              type: 'nested',
              to: '/accessoire-deco/',
              picto: require('../assets/menu-mobile/Accessoire_deco.jpg'),
              items: [
                {
                  text: 'Panneau Décoratif',
                  to: '/accessoire-deco/panneau-decoratif/'
                },
                {
                  text: 'Ruban',
                  to: '/accessoire-deco/ruban/'
                },
                {
                  text: 'Ficelle',
                  to: '/accessoire-deco/ficelle/'
                },
                {
                  text: 'Plumes déco',
                  to: '/accessoire-deco/plumes-deco/'
                },
                {
                  text: 'Accessoires Cadeaux',
                  to: '/decoration-de-table/etiquettes/'
                },
                {
                  text: 'Contenant Dragées',
                  to: '/decoration-de-table/contenant-dragees/'
                },
                {
                  text: 'Fleurs artificielles',
                  to: '/fleurs-artificielles/'
                }
              ]
            },
            {
              text: 'Verrerie',
              to: '/verrerie/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Verrerie.jpg'),
              items: [
                {
                  text: 'Boite Alliance',
                  to: '/verrerie/boite-alliance/'
                },
                {
                  text: 'Distributeur de Boisson',
                  to: '/verrerie/distributeur-de-boisson/'
                },
                {
                  text: 'Bocaux en Verre',
                  to: '/verrerie/bocaux-en-verre/'
                },
                {
                  text: 'Bonbonnière',
                  to: '/verrerie/bonbonniere/'
                },
                {
                  text: 'Mason Jar',
                  to: '/decoration-de-table/mason-jar/'
                }
              ]
            },
            {
              text: 'Letter Board',
              to: '/letter-board/',
              type: 'link',
              picto: require('../assets/menu-mobile/Letter_board.jpg')
            },
            {
              text: 'Bulles et Confettis',
              to: '/confettis-bulles/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Bulles_et_confettis.jpg'),
              items: [
                {
                  text: 'Canon à Confettis',
                  to: '/confettis-bulles/canons-confettis/'
                },
                {
                  text: 'Confettis',
                  to: '/confettis-bulles/confettis/'
                },
                {
                  text: 'Push Confettis',
                  to: '/confettis-bulles/push-confettis/'
                },
                {
                  text: 'Bulles de Savon',
                  to: '/confettis-bulles/bulle-savon/'
                }
              ]
            }
          ]
        },
        {
          text: 'Décoration de Table',
          type: 'nested',
          picto: require('../assets/fixtures/menu-mobile/deco-anniversaire.png'),
          to: '/decoration-de-table/',
          sliderPicture: require('../assets/menu/Decoration_de_table.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_decoration_de_table.jpg')})`,
          items: [
            {
              text: 'Housse et Chemins',
              to: '/decoration-de-table/housse-de-chaise/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Housse_et_chemin.jpg'),
              items: [
                {
                  text: 'Housse de Chaise',
                  to: '/decoration-de-table/housse-de-chaise/'
                },
                {
                  text: 'Nappes',
                  to: '/decoration-de-table/nappe/'
                },
                {
                  text: 'Chemin de Table',
                  to: '/decoration-de-table/chemin-de-table/'
                },
                {
                  text: 'Centre de Table',
                  to: '/decoration-de-table/centre-de-table/'
                },
                {
                  text: 'Set de Table',
                  to: '/decoration-de-table/set-de-table/'
                }
              ]
            },
            {
              text: 'Vaisselle Jetable',
              type: 'nested',
              to: '/decoration-de-table/assiette/',
              picto: require('../assets/menu-mobile/Vaisselle_jetable.jpg'),
              items: [
                {
                  text: 'Assiette',
                  to: '/decoration-de-table/assiette/'
                },
                {
                  text: 'Couvert',
                  to: '/decoration-de-table/couvert/'
                },
                {
                  text: 'Verres',
                  to: '/decoration-de-table/verres/'
                },
                {
                  text: 'Dessous de Verre',
                  to: '/decoration-de-table/dessous-de-verre/'
                },
                {
                  text: 'Serviettes',
                  to: '/decoration-mariage/candy-bar-mariage/serviettes-mariage/'
                },
                {
                  text: 'Pailles',
                  to: '/decoration-de-table/pailles/'
                },
                {
                  text: 'Pic',
                  to: '/decoration-de-table/pics/'
                },
                {
                  text: 'Cake Toppers',
                  to: '/decoration-de-table/cake-toppers/'
                }
              ]
            },
            {
              text: 'Marquage',
              type: 'nested',
              to: '/decoration-de-table/marque-place/',
              picto: require('../assets/menu-mobile/Marquage.jpg'),
              items: [
                {
                  text: 'Marque Place',
                  to: '/decoration-de-table/marque-place/'
                },
                {
                  text: 'Etiquettes',
                  to: '/decoration-de-table/etiquettes/'
                }
              ]
            },
            {
              text: 'Contenant à Dragées',
              to: '/decoration-de-table/contenant-dragees/',
              type: 'nested',
              picto: require('../assets/menu-mobile/Contenant_a_dragees.jpg'),
              items: [
                {
                  text: 'Sachet',
                  to: '/decoration-de-table/sachet/'
                }
              ]
            }
          ]
        },
        {
          text: 'Outdoor',
          type: 'nested',
          picto: require('../assets/menu-mobile/Outdoor.jpg'),
          sliderPicture: require('../assets/menu/Outdoor.png'),
          sliderBackgroundImage: `url(${require('../assets/menu/Fond_outdoor.jpg')})`,
          items: [
            {
              text: 'Jardinage',
              to: '/jardinage/',
              picto: require('../assets/menu-mobile/Jardinage.jpg'),
              items: [
                {
                  text: 'Composteur',
                  to: '/jardinage/composteur-de-jardin/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Serre de Jardin',
                  to: '/jardinage/serre-de-jardin/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Potager, Jardinière, Sacs de Plantation',
                  to: '/jardinage/potager-jardiniere-carre-potager/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Arrosage',
                  to: '/jardinage/arrosage/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Outils de Jardinage',
                  to: '/jardinage/outils-jardinage/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                },
                {
                  text: 'Outils de Jardin Motorisés',
                  to: '/jardinage/outils-jardin-motorises/',
                  picto: require('../assets/menu-mobile/Guirlande_lettre.jpg')
                }
              ]
            }
          ]
        }
      ]
    },
    {
      text: 'Déco Mariage',
      type: 'nested',
      picto: require('../assets/fixtures/menu-mobile/deco-mariage.png'),
      items: [
        {
          type: 'nested',
          text: 'Déco table Mariage',
          to: '/decoration-mariage/deco-table-mariage/',
          sliderBackgroundImage: `url(${require('../assets/menu/Deco_table_mariage.jpg')})`,
          sliderPicture: require('../assets/menu/Deco_table_mariage.png'),
          items: [
            {
              type: 'link',
              text: 'Chemin de table de mariage',
              picto: require('../assets/menu-mobile/Chemin_de_table_mariage.jpg'),
              to: '/decoration-mariage/deco-table-mariage/chemins-de-table-mariage/'
            },
            {
              type: 'link',
              text: 'Marque places de mariage',
              picto: require('../assets/menu-mobile/Marque_places_de_mariage.jpg'),
              to: '/decoration-mariage/deco-table-mariage/marque-place-mariage/'
            },
            {
              type: 'link',
              text: 'Bougies de mariage',
              picto: require('../assets/menu-mobile/Bougie_mariage.jpg'),
              to: '/decoration-mariage/deco-table-mariage/bougies-mariage/'
            },
            {
              type: 'link',
              text: 'Petite décoration',
              picto: require('../assets/menu-mobile/Petite_decoration.jpg'),
              to: '/decoration-mariage/deco-table-mariage/petite-decoration-table-mariage/'
            },
            {
              type: 'link',
              text: 'Bougeoirs et photophores',
              picto: require('../assets/menu-mobile/Bougeoirs_et_photophores.jpg'),
              to: '/decoration-mariage/deco-table-mariage/bougeoirs-photophores-mariage/'
            }
          ]
        },
        {
          type: 'nested',
          text: 'Décoration salle mariage',
          to: '/decoration-mariage/decoration-salle-mariage/',
          sliderBackgroundImage: `url(${require('../assets/menu/Decoration_salle_mariage.jpg')})`,
          sliderPicture: require('../assets/menu/Decoration_salle_mariage.png'),
          items: [
            {
              type: 'link',
              text: 'Lampions de mariage',
              picto: require('../assets/menu-mobile/Lampions_de_mariage.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/lampion-papier-mariage/'
            },
            {
              type: 'link',
              text: 'Rosace papier pour mariage',
              picto: require('../assets/menu-mobile/Rosace_papier_pour_mariage.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/rosace-papier-mariage/'
            },
            {
              type: 'link',
              text: 'Attrape rêves de mariage',
              picto: require('../assets/menu-mobile/Attrape_reves_de_mariage.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/attrape-reve-mariage/'
            },
            {
              type: 'link',
              text: 'Rubans pour mariage',
              picto: require('../assets/menu-mobile/Rubans_pour_mariage.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/rubans-mariage/'
            },
            {
              type: 'link',
              text: 'Guirlandes lumineuses de mariage',
              picto: require('../assets/menu-mobile/Guirlandes_lumineuses_de_mariage.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/guirlande-lumineuse-mariage/'
            },
            {
              type: 'link',
              text: 'Lanternes de mariage',
              picto: require('../assets/menu-mobile/Lanternes_de_mariage.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/lanterne-mariage/'
            },
            {
              type: 'link',
              text: 'Ballons pour mariage',
              picto: require('../assets/menu-mobile/Ballons_pour_mariage.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/ballons-mariage/'
            },
            {
              type: 'link',
              text: 'Panneaux et pancartes',
              picto: require('../assets/menu-mobile/Panneaux_et_pancartes.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/panneaux-pancarte-mariage/'
            },
            {
              type: 'link',
              text: 'Objets décoratifs',
              picto: require('../assets/menu-mobile/Objets_decoratifs.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/objets-decoratifs-mariage/'
            },
            {
              type: 'link',
              text: 'Housse de chaise mariage',
              picto: require('../assets/menu-mobile/Housse_de_chaise_mariage.jpg'),
              to: '/decoration-mariage/decoration-salle-mariage/housse-de-chaise-mariage/'
            }
          ]
        },
        {
          type: 'nested',
          text: 'Thème Mariage',
          to: '/decoration-mariage/theme-mariage/',
          sliderBackgroundImage: `url(${require('../assets/menu/Theme_mariage.jpg')})`,
          sliderPicture: require('../assets/menu/Theme_mariage.png'),
          items: [
            {
              type: 'link',
              to: '/decoration-mariage/theme-mariage/mariage-champetre/',
              picto: require('../assets/menu-mobile/Champetre.jpg'),
              text: 'Champêtre'
            },
            {
              type: 'link',
              to: '/decoration-mariage/theme-mariage/mariage-nature/',
              picto: require('../assets/menu-mobile/Nature.jpg'),
              text: 'Nature'
            },
            {
              type: 'link',
              text: 'Bohème',
              picto: require('../assets/menu-mobile/Boheme.jpg'),
              to: '/decoration-mariage/theme-mariage/mariage-boheme/'
            },
            {
              type: 'link',
              to: '/decoration-mariage/theme-mariage/mariage-chic/',
              picto: require('../assets/menu-mobile/Chic.jpg'),
              text: 'Chic'
            },
            {
              type: 'link',
              to: '/decoration-mariage/theme-mariage/la-vie-en-rose/',
              picto: require('../assets/menu-mobile/La_vie_en_rose.jpg'),
              text: 'La vie en Rose'
            },
            {
              type: 'link',
              to: '/decoration-mariage/theme-mariage/rustique-chic/',
              picto: require('../assets/menu-mobile/Rustic_chic.jpg'),
              text: 'Rustique Chic'
            },
            {
              type: 'link',
              text: 'Vintage',
              picto: require('../assets/menu-mobile/Vintage.jpg'),
              to: '/decoration-mariage/theme-mariage/mariage-vintage/'
            },
            {
              type: 'link',
              to: '/decoration-mariage/theme-mariage/mariage-romantique/',
              picto: require('../assets/menu-mobile/Romantique.jpg'),
              text: 'Romantique'
            },
            {
              type: 'link',
              text: 'Tropical',
              picto: require('../assets/menu-mobile/Tropical.jpg'),
              to: '/decoration-mariage/theme-mariage/mariage-tropical/'
            },
            {
              type: 'link',
              text: 'Mexicain',
              picto: require('../assets/menu-mobile/Mexicain.jpg'),
              to: '/decoration-mariage/theme-mariage/mariage-mexicain/'
            },
            {
              type: 'link',
              text: 'Antique',
              picto: require('../assets/menu-mobile/Antique.jpg'),
              to: '/decoration-mariage/theme-mariage/metal-antique/'
            },
            {
              type: 'link',
              text: 'Industriel',
              picto: require('../assets/menu-mobile/Industriel.jpg'),
              to: '/decoration-mariage/theme-mariage/mariage-indutriel/'
            }
          ]
        },
        {
          type: 'nested',
          text: 'Candy Bar',
          to: '/decoration-mariage/candy-bar-mariage/',
          sliderBackgroundImage: `url(${require('../assets/menu/Candy_bar.jpg')})`,
          sliderPicture: require('../assets/menu/Candy_bar.png'),
          items: [
            {
              type: 'link',
              text: 'Mason Jar pour mariage',
              picto: require('../assets/menu-mobile/Mason_jar_pour_mariage.jpg'),
              to: '/decoration-mariage/candy-bar-mariage/mason-jar-mariage/'
            },
            {
              type: 'link',
              text: 'Bocaux en verre de mariage',
              picto: require('../assets/menu-mobile/Bocaux_en_verre_de_mariage.jpg'),
              to: '/decoration-mariage/candy-bar-mariage/bocal-verre-mariage/'
            },
            {
              type: 'link',
              text: 'Assiettes de mariage',
              picto: require('../assets/menu-mobile/Assiettes_de_mariage.jpg'),
              to: '/decoration-mariage/candy-bar-mariage/assiettes-jetables-mariage/'
            },
            {
              type: 'link',
              text: 'Guirlandes de mariage',
              picto: require('../assets/menu-mobile/Guirlandes_de_mariage.jpg'),
              to: '/decoration-mariage/candy-bar-mariage/guirlande-mariage/'
            },
            {
              type: 'link',
              text: 'Gobelets de mariage',
              picto: require('../assets/menu-mobile/Gobelets_de_mariage.jpg'),
              to: '/decoration-mariage/candy-bar-mariage/gobelets-mariage/'
            },
            {
              type: 'link',
              text: 'Sachets pour mariage',
              picto: require('../assets/menu-mobile/Sachets_pour_mariage.jpg'),
              to: '/decoration-mariage/candy-bar-mariage/sachet-mariage/'
            },
            {
              type: 'link',
              text: 'Pailles pour mariage',
              picto: require('../assets/menu-mobile/Pailles_pour_mariage.jpg'),
              to: '/decoration-mariage/candy-bar-mariage/pailles-mariage/'
            }
          ]
        },
        {
          type: 'nested',
          text: 'Déco Cérémonie',
          to: '/decoration-mariage/deco-ceremonie/',
          sliderBackgroundImage: `url(${require('../assets/menu/Deco_ceremonie.jpg')})`,
          sliderPicture: require('../assets/menu/Deco_ceremonie.png'),
          items: [
            {
              type: 'link',
              text: 'Canons à confettis pour mariage',
              picto: require('../assets/menu-mobile/Canons_a_confettis_pour_mariage.jpg'),
              to: '/decoration-mariage/deco-ceremonie/canon-confettis-mariage/'
            },
            {
              type: 'link',
              text: 'Bulles de savon',
              picto: require('../assets/menu-mobile/Bulles_de_savon.jpg'),
              to: '/decoration-mariage/deco-ceremonie/bulle-savon-mariage/'
            }
          ]
        },
        {
          type: 'nested',
          text: 'Accessoires Gâteaux',
          to: '/decoration-mariage/accessoires-gateaux/',
          sliderBackgroundImage: `url(${require('../assets/menu/Accessoires_gateaux.jpg')})`,
          sliderPicture: require('../assets/menu/Accessoires_gateaux.png'),
          items: [
            {
              type: 'link',
              text: 'Cake Topper',
              picto: require('../assets/menu-mobile/Cake_topper.jpg'),
              to: '/decoration-mariage/accessoires-gateaux/cake-topper-mariage/'
            },
            {
              type: 'link',
              text: 'Cierges Magiques',
              picto: require('../assets/menu-mobile/Cierges_magiques.jpg'),
              to: '/decoration-mariage/accessoires-gateaux/cierges-magiques-mariage/'
            }
          ]
        },
        {
          type: 'nested',
          text: 'Animations mariage',
          to: '/decoration-mariage/animations-mariage/',
          sliderBackgroundImage: `url(${require('../assets/menu/Animations_mariage.jpg')})`,
          sliderPicture: require('../assets/menu/Animation_mariage.png'),
          items: [
            {
              type: 'link',
              text: 'Livre d\'or de mariage',
              picto: require('../assets/menu-mobile/Livre_d_or_de_mariage.jpg'),
              to: '/decoration-mariage/animations-mariage/livre-or-mariage/'
            },
            {
              type: 'link',
              text: 'Photobooth Mariage',
              picto: require('../assets/menu-mobile/Photobooth_mariage.jpg'),
              to: '/decoration-mariage/animations-mariage/photobooth-mariage/'
            },
            {
              type: 'link',
              text: 'Lanternes volantes de mariage',
              picto: require('../assets/menu-mobile/Lanternes_volantes_de_mariage.jpg'),
              to: '/decoration-mariage/animations-mariage/lanternes-volantes-mariage/'
            },
            {
              type: 'link',
              text: 'Piñata pour mariage',
              picto: require('../assets/menu-mobile/Pinata_pour_mariage.jpg'),
              to: '/decoration-mariage/animations-mariage/pinata-mariage/'
            }
          ]
        },
        {
          text: 'Décoration par couleur',
          type: 'nested',
          to: '/decoration-mariage/mariage-par-couleur/',
          items: [
            {
              text: 'Blanc',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-blanc/'
            },
            {
              text: 'Ivoire',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-ivoire/'
            },
            {
              text: 'Jaune Pâle',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-jaune-pale/'
            },
            {
              text: 'Jaune',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-jaune/'
            },
            {
              text: 'Or',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-or/'
            },
            {
              text: 'Orange',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-orange/'
            },
            {
              text: 'Corail',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-corail/'
            },
            {
              text: 'Cuivre',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-cuivre/'
            },
            {
              text: 'Rouge',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-rouge/'
            },
            {
              text: 'Fuchsia',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-fuchsia/'
            },
            {
              text: 'Rose Pâle',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-rose-pale/'
            },
            {
              text: 'Pêche',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-peche/'
            },
            {
              text: 'Rose Champagne',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-rose-champagne/'
            },
            {
              text: 'Rose Blush',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-rose-blush/'
            },
            {
              text: 'Parme',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-parme/'
            },
            {
              text: 'Bleu Lavande',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-bleu-lavande/'
            },
            {
              text: 'Bleu Roi',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-bleu-roi/'
            },
            {
              text: 'Bleu Navy',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-bleu-navy/'
            },
            {
              text: 'Bleu Pastel',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-bleu-pastel/'
            },
            {
              text: 'Turquoise',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-turquoise/'
            },
            {
              text: 'Bleu Pétrole',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-bleu-petrole/'
            },
            {
              text: 'Aqua Marine',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-aquamarine/'
            },
            {
              text: 'Vert Eau',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-vert-eau/'
            },
            {
              text: 'Jade',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-jade/'
            },
            {
              text: 'Vert Anis',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-vert-anis/'
            },
            {
              text: 'Vert foncé',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-vert-fonce/'
            },
            {
              text: 'Aubergine',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-aubergine/'
            },
            {
              text: 'Marsala',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-marsala/'
            },
            {
              text: 'Chocolat',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-chocolat/'
            },
            {
              text: 'Bronze',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-bronze/'
            },
            {
              text: 'Naturel',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-naturel/'
            },
            {
              text: 'Sable',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-naturel/'
            },
            {
              text: 'Argent',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-argent/'
            },
            {
              text: 'Gris',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-gris/'
            },
            {
              text: 'Noir',
              to: '/decoration-mariage/mariage-par-couleur/deco-mariage-noir/'
            }
          ]
        },
        {
          text: 'Déco de Fête',
          type: 'nested',
          items: [
            {
              text: 'Noël',
              picto: require('../assets/menu-mobile/Noel.jpg'),
              to: '/noel/'
            },
            {
              text: 'Déco Guinguette',
              picto: require('../assets/menu-mobile/Deco_guinguette.jpg'),
              to: '/deco-guinguette/'
            },
            {
              text: 'Déco Automne',
              picto: require('../assets/menu-mobile/Deco_automne.jpg'),
              to: '/deco-automne/'
            },
            {
              text: 'Dia de los muertos',
              picto: require('../assets/menu-mobile/Dia_de_los_muertos.jpg'),
              to: '/dia-de-los-muertos/'
            },
            {
              text: 'Anniversaire enfant',
              picto: require('../assets/menu-mobile/Anniversaire_enfant.jpg'),
              to: '/anniversaire-enfant/'
            },
            {
              text: 'Décoration Anniversaire',
              picto: require('../assets/menu-mobile/Decoration_anniversaire.jpg'),
              to: '/decoration-anniversaire/'
            },
            {
              text: 'Décoration Baptême',
              picto: require('../assets/menu-mobile/Decoration_bapteme.jpg'),
              to: '/decoration-bapteme/'
            },
            {
              text: 'Outdoor',
              picto: require('../assets/menu-mobile/Outdoor.jpg'),
              to: '/outdoor/'
            },
            {
              text: 'Fête des mères',
              picto: require('../assets/menu-mobile/Fete_des_meres.jpg'),
              to: '/la-fete-des-meres/'
            },
            {
              text: 'DIY',
              picto: require('../assets/menu-mobile/DIY.jpg'),
              to: '/decoration-diy/'
            },
            {
              text: 'Naissance',
              picto: require('../assets/menu-mobile/Naissance.jpg'),
              to: '/content/142-deco-naissance/'
            },
            {
              text: 'Deuil',
              picto: require('../assets/menu-mobile/Deuil.jpg'),
              to: '/content/144-deco-enterrement/'
            },
            {
              text: 'Tête à tête amoureux',
              picto: require('../assets/menu-mobile/Tete_a_tete_amoureux.jpg'),
              to: '/content/86-deco-romantique/'
            },
            {
              text: 'Saint-Valentin',
              picto: require('../assets/menu-mobile/Saint_valentin.jpg'),
              to: '/saint-valentin/'
            },
            {
              text: 'Garden Party',
              picto: require('../assets/menu-mobile/Garden_party.jpg'),
              to: '/content/119-deco-garden-party/'
            },
            {
              text: '14 Juillet',
              picto: require('../assets/menu-mobile/14_juillet.jpg'),
              to: '/content/132-deco-fete-nationale/'
            },
            {
              text: 'Halloween',
              picto: require('../assets/menu-mobile/Halloween.jpg'),
              to: '/halloween/'
            },
            {
              text: 'Nouvel an',
              picto: require('../assets/menu-mobile/Nouvel_an.jpg'),
              to: '/nouvel-an/'
            }
          ],
          sliderBackgroundImage: `url(${require('../assets/menu/Deco_fete.jpg')})`,
          sliderPicture: require('../assets/menu/Deco_fete.png')
        },
        {
          text: 'Toute la déco Mariage',
          to: '/decoration-mariage/'
        }
      ]
    },
    {
      text: 'Déco Anniversaire',
      type: 'link',
      picto: require('../assets/menu-mobile/Decoration_anniversaire.jpg'),
      to: '/anniversaire-enfant/'
    },
    {
      text: 'Déco couleur',
      type: 'link',
      to: '/couleur-blanc',
      picto: require('../assets/fixtures/menu-mobile/deco-couleur.png')
    },
    {
     type: 'link',
     text: 'Déstockage',
     picto: require('../assets/fixtures/menu-mobile/deco-par-theme.png'),
     textColor: 'white',
     backgroundColor: 'black',
     to: '/destockage/'
    },
    //{
    //  type: 'link',
    //  text: 'Noël',
    //  picto: require('../assets/fixtures/menu-mobile/deco-par-theme.png'),
    //  textColor: 'white',
    //  backgroundColor: '#d60724',
    //  to: '/noel/'
    //},
    //{
      //type: 'link',
      //text: 'Nouvel An',
      //picto: require('../assets/fixtures/menu-mobile/deco-par-theme.png'),
      //textColor: 'white',
      //backgroundColor: '#d60724',
      //to: '/nouvel-an/'
    //},
    //{
      //type: 'link',
      //text: 'Soldes !',
      //picto: require('../assets/fixtures/menu-mobile/deco-par-theme.png'),
      //textColor: 'white',
      //backgroundColor: '#156393',
      //to: '/soldes-ete/'
    //},
    //{
      // type: 'link',
      // text: 'Black Week',
      // picto: require('../assets/fixtures/menu-mobile/deco-par-theme.png'),
      // textColor: 'white',
      // backgroundColor: 'black',
      // to: '/black-week/'
     //},
    //{
      //type: 'link',
      //text: 'Soldes',
      //picto: require('../assets/fixtures/menu-mobile/deco-par-theme.png'),
      //textColor: 'white',
      //backgroundColor: '#89d482',
      //to: '/soldes-ete/'
    //},
    {
      type: 'external_link',
      text: 'Blog',
      picto: require('../assets/fixtures/menu-mobile/blog.png'),
      href: 'https://www.skylantern.fr/blog/'
    },
    {
      type: 'link',
      text: 'Espace Pro',
      picto: require('../assets/fixtures/menu-mobile/espace-pro.png'),
      to: '/espace-pro/'
    }
  ]
}
