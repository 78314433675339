<template>
  <div class="sky__search-wrapper" :class="small ? 'small' : ''">
    <input
      :class="[small ? 'small' : '', category ? 'px-4' : 'px-8']"
      :value="$attrs.value"
      class="sky__search-input focus:outline-none bg-sky_grey_darker"
      name="skylantern__search"
      type="text"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      v-on="listeners"
    >
  </div>
</template>

<script>
import omit from 'lodash.omit'

export default {
  props: {
    small: {
      required: false,
      default: false,
      type: Boolean
    },
    placeholder: {
      required: false,
      default: 'Néon, Mariage, Bohême',
      type: String
    },
    category: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners () {
      return omit(this.$listeners, ['input'])
    }
  }
}
</script>

<style lang="scss" scoped>
  .sky__search-input {
    width: 100%;
    border-radius: 50px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 45px;
    &.small {
      height: 40px;
    }
  }

  .sky__search-wrapper {
    position: relative;
    height: 45px;
    &.small {
      height: 40px;
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 7px;
      top: 0;
      width: 47px;
      height: 47px;
      background-image: url(../../../assets/icons/interface-search.svg?img);
      background-size: cover;
      z-index: 50;
    }
    &.small {
      &::after {
        transform: scale(0.8);
        top: -4px
      }
    }
  }
</style>
