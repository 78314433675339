var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex relative"},[_c('div',{staticClass:"bg-sky_grey_darker relative cursor-pointer block",style:({
      width: '80px',
      height: '80px',
      backgroundSize: 'cover',
      backgroundImage: _vm.product.background ? ("url(" + (_vm.product.background) + ")") : undefined
    })},[_c('img',{staticClass:"absolute left-0 right-0 bottom-0 top-0",attrs:{"src":_vm.product.cover + '?resize=true&width=80&height=80',"alt":"","loading":"lazy"}})]),_vm._v(" "),_c('div',{staticClass:"flex-1 relative ml-3 py-2"},[_c('div',{staticClass:"flex items-start justify-between"},[_c('div',{staticClass:"text-sm sky__search-product-result",staticStyle:{"max-width":"70%"},domProps:{"innerHTML":_vm._s(_vm.product._highlightResult.label.value)}}),_vm._v(" "),_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"text-sm",class:{
            'font-bold': _vm.product.price !== _vm.product.crossed_price,
            'text-sky_secondary': _vm.product.price !== _vm.product.crossed_price
          }},[_vm._v("\n          "+_vm._s(_vm.$helpers.formatCurrency(_vm.product.price))+"\n        ")]),_vm._v(" "),(_vm.product.price !== _vm.product.crossed_price)?_c('div',{staticClass:"ml-2 text-xs hover:no-underline line-through text-gray-400"},[_vm._v("\n          "+_vm._s(_vm.$helpers.formatCurrency(_vm.product.crossed_price))+"\n        ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"text-xs"},[(_vm.product.stock_status === 'available')?[_c('span',{staticClass:"text-gray-500"},[_vm._v("#"+_vm._s(_vm.product.sku))]),_vm._v(" - "),_c('span',{staticClass:"text-success"},[_vm._v("En stock")])]:(_vm.product.stock_status === 'available_later')?[_c('span',{staticClass:"text-gray-500"},[_vm._v("#"+_vm._s(_vm.product.sku))]),_vm._v(" - "),_c('span',{staticClass:"text-orange-300"},[_vm._v("Pré-Commande")])]:(_vm.product.stock_status === 'out_of_stock')?[_c('span',{staticClass:"text-gray-500"},[_vm._v("#"+_vm._s(_vm.product.sku))]),_vm._v(" - "),_c('span',{staticClass:"text-red-500"},[_vm._v("Rupture de stock")])]:_vm._e()],2),_vm._v(" "),(_vm.discountPercentage)?_c('div',{staticClass:"absolute font-funny text-sky_orange text-white rounded-full",staticStyle:{"bottom":"10px","right":"10px"}},[_vm._v("\n      -"+_vm._s(_vm.discountPercentage)+"%\n    ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }