export default {
  state () {
    return {
      showMobileMenu: false,
      host: null,
      paypalClientId: null
    }
  },
  mutations: {
    SHOW_MOBILE_MENU (state) {
      state.showMobileMenu = true
    },
    HIDE_MOBILE_MENU (state) {
      state.showMobileMenu = false
    },
    TOGGLE_MOBILE_MENU (state) {
      state.showMobileMenu = !state.showMobileMenu
    },
    SET_HOST (state, host) {
      state.host = host
    },
    SET_PAYPAL_CLIENT_ID (state, clientId) {
      state.paypalClientId = clientId
    }
  }
}
