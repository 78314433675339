<template>
  <div>
    <site-header />
    <div>
      <nuxt />
    </div>
    <site-footer v-if="$route.path !== '/transaction' && $route.path !== '/commande'" />
    <modals-container />
  </div>
</template>

<script>
import { ModalsContainer } from 'vue-final-modal'
import SiteHeader from '../components/header/SiteHeader'
import SiteFooter from '../components/footer/Footer'

export default {
  components: {
    SiteHeader,
    SiteFooter,
    ModalsContainer
  }
}
</script>
