<template>
  <div>
    <div
      class="sky__mobile-bar"
    >
      <div class="flex pr-4 justify-between items-center">
        <div class="flex">
          <div
            v-wave
            class="cursor-pointer flex items-center px-4"
            @click.stop="$store.commit('ui/TOGGLE_MOBILE_MENU')"
          >
            <icon-nav-cross v-if="showMobileMenu" style="width: 36px;" />
            <icon-burger v-else style="width: 36px;" />
          </div>
          <nuxt-link to="/recherche">
            <icon-search style="width: 50px; height: 50px;" @click="$router.push('/recherche')" />
          </nuxt-link>
        </div>
        <a href="/" class="flex">
          <icon-logo style="width: 60px; height: 50px;" />
        </a>
        <div class="flex justify-end" style="width: 86px">
          <nuxt-link to="/mon-compte">
            <icon-account style="width: 36px;" class="mx-auto" />
          </nuxt-link>
          <my-cart-button />
        </div>
      </div>
    </div>
    <div
      class="bg-white block lg:hidden"
      :style="{
        height: `${mobileBarHeight}px`
      }"
    />
  </div>
</template>

<script>
import MyCartButton from './menu/MyCartButton'
export default {
  components: {
    MyCartButton
  },
  data () {
    return {
      mobileBarHeight: 50
    }
  },
  computed: {
    showMobileMenu () {
      return this.$store.state.ui.showMobileMenu
    }
  }
}
</script>

<style lang="scss" scoped>
  .sky__mobile-bar {
    @apply fixed z-50 bg-white left-0 right-0 border-b bg-white;
    height: 50px;
    @screen lg {
      @apply hidden;
    }
  }
</style>
