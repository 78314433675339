<template>
  <div>
    <div
      ref="desktop-fixed-bar"
      class="sky__desktop-fixed-bar bg-white"
      :class="{
        'fixed': true,
        'lg:fixed': isScrolled,
        'lg:static': !isScrolled,
        'left-0': true,
        'right-0': true,
        'top-0': true
      }"
    >
      <div
        class="sky__bar items-center justify-center pb-2 hidden lg:flex z-20 relative bg-white"
        :class="{
          'is-scrolled': isMenuSmall
        }"
      >
        <div
          class="container items-end flex"
          :class="$store.state.screen.ordering ? 'items-center' : 'items-end'"
        >
          <div
            v-if="!$store.state.screen.ordering"
            class="sky__search flex flex-col justify-end flex-1"
          >
            <div
              v-show="!isMenuSmall"
              class="text-center text-sky_blue text-xl font-funny md:text-xl lg:text-2xl xl:text-3xl"
              style="height: 45px; overflow: hidden; position: relative; top: 5px;"
            >
              Créateur d'enchantement depuis 2007
            </div>
            <global-search-component
              :small="isMenuSmall"
            />
          </div>
          <nuxt-link to="/" class="sky__logo block text-center flex-1">
            <icon-logo
              class="logo"
              :class="{
                'is-scrolled': isMenuSmall,
                'mx-auto': !$store.state.screen.ordering
              }"
              :style="{
                'transform': $store.state.screen.ordering ? 'scale(0.7)' : 'scale(1)',
                position: 'relative',
                top: '4px'
              }"
            />
          </nuxt-link>
          <div v-if="!$store.state.screen.ordering" class="sky__nav flex-1 flex align-end justify-end text-xs">
            <nuxt-link to="/magasins" class="sky__nav-item">
              <icon-stores style="width: 38px;" class="mx-auto" />
              <span v-show="!isMenuSmall" class="hidden xl:block">Magasins</span>
            </nuxt-link>
            <nuxt-link to="/mon-compte/mes-projets" class="sky__nav-item">
              <icon-projects style="width: 38px;" class="mx-auto" />
              <span v-show="!isMenuSmall" class="hidden xl:block">Mes projets</span>
            </nuxt-link>
            <nuxt-link to="/mon-compte" class="sky__nav-item">
              <icon-account style="width: 38px;" class="mx-auto" />
              <span v-show="!isMenuSmall" class="hidden xl:block">Mon compte</span>
            </nuxt-link>
            <my-cart-button :small="isMenuSmall" class="sky__nav-item" />
          </div>
          <div v-else-if="!isMenuSmall" class="text-right">
            <div class="font-funny text-2xl flex justify-end">
              Service client
              <img class="ml-1" src="../../../assets/icons/interface-comment.svg?img" style="width: 34px; height: 34px;" alt="">
            </div>
            <div class="font-bold text-sm">
              Du lundi au vendredi au
            </div>
            <div class="font-bold text-sm">
              +33 (0)3 66 72 85 22
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!$store.state.screen.ordering"
        ref="sky-menu"
      >
        <sky-menu />
      </div>
    </div>
    <div
      class="bg-white hidden lg:block"
      :style="{
        height: totalFixedHeight + 'px'
      }"
    />
  </div>
</template>

<script>
import GlobalSearchComponent from '../search/GlobalSearchComponent'
import Menu from './Menu'
import MyCartButton from './MyCartButton'

export default {
  components: {
    SkyMenu: Menu,
    MyCartButton,
    GlobalSearchComponent
  },
  computed: {
    isScrolled () {
      return this.$store.getters['screen/isScrolled']
    },
    isMenuSmall () {
      return this.isScrolled
    },
    totalFixedHeight () {
      if (!this.$store.getters['screen/isScrolled']) {
        return 0
      }

      return 166
    }
  }
}
</script>

<style lang="scss" scoped>
  .sky__search {
    width: 400px;
  }
  .sky__logo {
    width: 416px;
    .logo {
      height: 94px;
      // transition-duration: 0.2s;
      &.is-scrolled {
        height: 45px;
      }
    }
  }
  .sky__bar {
    height: 120px;
    // transition-duration: 0.2s;
    // z-index: 1000;
    &.is-scrolled {
      height: 56px;
    }
  }
  .sky__nav-item {
    @apply ml-5;
  }
  .sky__desktop-fixed-bar {
    z-index: 1000;
  }
</style>
