import axios from 'axios'

export default ({ store, app, route, nuxtState, req }, inject) => {
  const instance = axios.create({
    baseURL: process.client ? '/api' : `http://localhost:${process.env.PORT || 3010}/api`
  })
  instance.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      if (req && req.headers.pragma === 'no-cache') {
        Object.assign(config.headers, {
          pragma: 'no-cache'
        })
      }

      return config
    },
    error => Promise.reject(error)
  )
  inject('api', instance)
}
