<script>
export default {
  props: {
    href: {
      required: false,
      type: String,
      default: undefined
    },
    to: {
      required: false,
      type: String,
      default: undefined
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  render (h) {
    if (this.href) {
      return h('a', {
        attrs: {
          href: this.href,
          ...this.$attrs
        },
        on: this.$listeners
      }, this.$slots.default)
    }

    if (this.to) {
      return h('nuxt-link', {
        props: {
          to: this.to
        },
        attrs: this.$attrs,
        on: this.$listeners
      }, this.$slots.default)
    }

    return h('button', {
      attrs: {
        disabled: this.loading,
        ...this.$attrs
      },
      on: this.$listeners
    }, this.$slots.default)
  }
}
</script>
