<template>
  <div class="container mx-auto flex flex-wrap">
    <div class="w-1/3 lg:w-1/4 text-center">
      <icon-insurance-cup class="reinsurance-icon" />
      <div class="reinsurance-title">
        La marque n1
      </div>
      <div class="reinsurance-subtitle">
        De la décoration Mariage
      </div>
    </div>
    <div class="w-1/3  lg:w-1/4 text-center">
      <icon-insurance-secure class="reinsurance-icon" />
      <div class="reinsurance-title">
        Paiement 100%
      </div>
      <div class="reinsurance-subtitle">
        Sécurisé
      </div>
    </div>
    <div class="w-1/3  lg:w-1/4 text-center">
      <icon-insurance-thumb class="reinsurance-icon" />
      <div class="reinsurance-title">
        Service client pro
      </div>
      <div class="reinsurance-subtitle">
        Basé en France
      </div>
    </div>
    <div class="order-first lg:order-none lg:w-1/4 text-center">
      <div class="text-2xl font-funny">
        Entièrement satisfaite!
      </div>
      <p class="text-xs">
        "Comme toujours, l'équipe Sky Lantern est rapide, efficace, les produits sont suffisamment protégés, sans excès d'emballage, les produits (guirlande guinguette led) au top ! "</p>
      <div class="text-xs text-sky_grey mb-2">
        5/5 par Aurélie - le 23/08/2023
      </div>
      <div class="flex justify-center mb-3">
        <notation :notation="4.6" :max="5" />
      </div>
      <div class="text-5xl font-funny">
        Noté 4,7/5
      </div>
      <div class="text-xs">
        Par <a href="https://www.ekomi.fr/avis-clients-skylanternfr.html" target="_blank" class="underline">3022 clients</a>
      </div>
    </div>
  </div>
</template>

<script>
import Notation from '../general/Notation'

export default {
  components: {
    Notation
  }
}
</script>

<style lang="scss" scoped>
  .reinsurance-icon {
    @apply mx-auto;
    width: 50px;
    @screen lg {
      width: 80px;
    }
  }
  .reinsurance-title {
    @apply text-lg font-funny;
    @screen lg {
      @apply text-2xl;
    }
  }
  .reinsurance-subtitle {
    @apply text-xs font-bold;
    @screen lg {
      @apply text-base;
    }
  }
</style>
