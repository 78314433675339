<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
  render (h) {
    if (this.item.href) {
      return h('a', {
        attrs: {
          href: this.item.href,
          target: '_blank'
        },
        on: this.$listeners,
        directives: [
          {
            name: 'wave'
          }
        ]
      }, this.$slots.default)
    }

    if (this.item.to) {
      return h('nuxt-link', {
        props: {
          to: this.item.to
        },
        nativeOn: this.$listeners,
        directives: [
          {
            name: 'wave'
          }
        ]
      }, this.$slots.default)
    }

    return h('a', {
      attrs: {
        href: '#'
      },
      on: {
        click: (e) => {
          this.$emit('open')
          e.preventDefault()
        },
        ...this.$listeners
      },
      directives: [
        {
          name: 'wave'
        }
      ]
    }, this.$slots.default)
  }
}
</script>
