import storage from 'local-storage-fallback'
import { v4 as uuid } from 'uuid'
import omit from 'lodash.omit'
import omitBy from 'lodash.omitby'
import orderBy from 'lodash.orderby'
import isUndefined from 'lodash.isundefined'

export default {
  state () {
    return {
      cart: null,
      show: false,
      wishlists: [],
      ready: false,
      pusherChannel: null
    }
  },
  mutations: {
    setCart (state, cart) {
      state.cart = cart
    },
    setShow (state, show) {
      state.show = show
    },
    setWishlists (state, wishlists) {
      state.wishlists = wishlists
    },
    setReady (state, ready) {
      state.ready = ready
    },
    setPusherChannel (state, channel) {
      state.pusherChannel = () => channel
    }
  },
  actions: {
    async init ({ commit, dispatch, state }) {
      commit('setReady', false)
      let cartId = storage.getItem('yellaw-cart-id')
      if (!cartId) {
        cartId = uuid()
        storage.setItem('yellaw-cart-id', cartId)
      }
      const { data: cart } = await this.$api.get(`/carts/${cartId}`)
      if (state.pusherChannel) {
        state.pusherChannel().unbind()
      }
      const channel = this.$pusher.subscribe(cartId)
      commit('setPusherChannel', channel)
      channel.bind('cart-change', (data) => {
        if (data.tabId !== this.$tabId) {
          dispatch('refresh')
        }
      })
      commit('setCart', cart)
      commit('setReady', true)
    },
    async persist ({ state, commit }) {
      await this.$api.put(`/carts/${state.cart.id}`, omit(state.cart, ['total', '_products']))
      // We must update products in cart to update pricing
      const { data: cart } = await this.$api.get(`/carts/${state.cart.id}`)
      commit('setCart', {
        ...state.cart,
        _products: cart._products
      })
    },
    async refresh ({ state, commit }) {
      const { data: cart } = await this.$api.get(`/carts/${state.cart.id}`)
      commit('setCart', cart)
    },
    async addProduct ({ commit, state, dispatch }, { sku, label, cover, quantity, pricing, properties }) {
      const cart = JSON.parse(JSON.stringify(state.cart))
      let initialQuantity = 0
      const existingItem = cart.products.find(p => p.sku === sku)
      if (existingItem) {
        initialQuantity = existingItem.quantity
      }
      let newQuantity = initialQuantity + quantity
      if (newQuantity < 0) {
        newQuantity = 0
      }
      const getPrice = () => {
        const sortedDecreasingPrices = orderBy(pricing.decreasing_prices, ['minimal_quantity'], ['desc'])
        for (const dp of sortedDecreasingPrices) {
          if (dp.minimal_quantity < newQuantity) {
            return this.getters['auth/paysTaxes'] ? dp.unit_taxed_price : dp.unit_price
          }
        }
        return this.getters['auth/paysTaxes'] ? pricing.unit_taxed_price : pricing.unit_price
      }
      const price = getPrice()
      const newObject = omitBy({
        sku,
        label,
        cover,
        quantity: newQuantity,
        properties,
        price
      }, isUndefined)
      if (newQuantity === 0 && existingItem) {
        cart.products.splice(cart.products.indexOf(existingItem), 1)
      } else if (existingItem) {
        Object.assign(existingItem, newObject)
      } else {
        cart.products.unshift(Object.assign({
          properties: properties || {}
        }, newObject))
      }
      commit('setCart', cart)
      await dispatch('persist')
      return {
        price
      }
    },
    async removeProduct ({ commit, state, dispatch }, { sku }) {
      const cart = JSON.parse(JSON.stringify(state.cart))
      const items = cart.products.filter(p => p.sku === sku)
      for (const item of items) {
        cart.products.splice(cart.products.indexOf(item), 1)
      }
      commit('setCart', cart)
      await dispatch('persist')
    },
    async flushCart ({ commit, state, dispatch }) {
      const cart = JSON.parse(JSON.stringify(state.cart))
      cart.products = []
      commit('setCart', cart)
      await dispatch('persist')
    },
    async updateProductPrice ({ commit, state, dispatch }, { sku, price }) {
      const cart = JSON.parse(JSON.stringify(state.cart))
      const productItem = cart.products.find(p => p.sku === sku)
      if (productItem) {
        productItem.price = price
        commit('setCart', cart)
        await dispatch('persist')
      }
    },
    async updateManyProductPrices ({ commit, state, dispatch }, inputs) {
      const cart = JSON.parse(JSON.stringify(state.cart))
      let updated = false
      for (const { sku, price, crossedPrice } of inputs) {
        const productItem = cart.products.find(p => p.sku === sku)
        if (productItem) {
          updated = true
          productItem.price = price
          if (crossedPrice) {
            productItem.properties.crossed_price = crossedPrice
          }
        }
      }

      if (updated) {
        commit('setCart', cart)
        await dispatch('persist')
      }
    },
    async updateProductQuantity ({ commit, state, dispatch }, { sku, quantity }) {
      const cart = JSON.parse(JSON.stringify(state.cart))
      const productItem = cart.products.find(p => p.sku === sku)
      if (productItem) {
        productItem.quantity = quantity
        commit('setCart', cart)
        await dispatch('persist')
      }
    },
    async setFirebaseUserId ({ commit, state, dispatch }, userId) {
      const cart = JSON.parse(JSON.stringify(state.cart))
      cart.firebase_user_id = userId
      commit('setCart', cart)
      await dispatch('persist')
    },
    // eslint-disable-next-line camelcase
    async setShipping ({ commit, state, dispatch }, { shipping_service_id, shipping_service_name, price, cover, properties }) {
      const cart = JSON.parse(JSON.stringify(state.cart))
      Object.assign(cart.shipping, omitBy({
        shipping_service_id,
        shipping_service_name,
        price,
        cover
      }, isUndefined))
      if (properties) {
        Object.assign(cart.shipping.properties, properties)
      }
      commit('setCart', cart)
      await dispatch('persist')
    },
    async fetchWishlists ({ commit }) {
      const { data } = await this.$api.get('/wishlists')
      commit('setWishlists', data)
    }
  },
  getters: {
    itemsCount (state) {
      if (!state.cart) {
        return null
      }
      let total = 0
      for (const item of state.cart.products) {
        total += item.quantity
      }
      return total
    },
    total (state, getters) {
      return getters.productsTotal + getters.shippingTotal - state.cart.coupons_total
    },
    productsTotal (state) {
      if (!state.cart) {
        return 0
      }
      let total = 0
      for (const item of state.cart.products) {
        total += item.quantity * item.price
      }
      return total
    },
    shippingTotal (state) {
      if (!state.cart) {
        return 0
      }
      return state.cart.shipping.price
    }
  }
}
