<template>
  <div class="flex relative">
    <div
      :style="{
        width: '80px',
        height: '80px',
        backgroundSize: 'cover',
        backgroundImage: product.background ? `url(${product.background})` : undefined
      }"
      class="bg-sky_grey_darker relative cursor-pointer block"
    >
      <img class="absolute left-0 right-0 bottom-0 top-0" :src="product.cover + '?resize=true&width=80&height=80'" alt="" loading="lazy">
    </div>
    <div class="flex-1 relative ml-3 py-2">
      <div class="flex items-start justify-between">
        <div class="text-sm sky__search-product-result" style="max-width: 70%;" v-html="product._highlightResult.label.value" />
        <div class="flex items-center">
          <div
            class="text-sm"
            :class="{
              'font-bold': product.price !== product.crossed_price,
              'text-sky_secondary': product.price !== product.crossed_price
            }"
          >
            {{ $helpers.formatCurrency(product.price) }}
          </div>
          <div v-if="product.price !== product.crossed_price" class="ml-2 text-xs hover:no-underline line-through text-gray-400">
            {{ $helpers.formatCurrency(product.crossed_price) }}
          </div>
        </div>
      </div>
      <div class="text-xs">
        <template v-if="product.stock_status === 'available'">
          <span class="text-gray-500">#{{ product.sku }}</span> - <span class="text-success">En stock</span>
        </template>
        <template v-else-if="product.stock_status === 'available_later'">
          <span class="text-gray-500">#{{ product.sku }}</span> - <span class="text-orange-300">Pré-Commande</span>
        </template>
        <template v-else-if="product.stock_status === 'out_of_stock'">
          <span class="text-gray-500">#{{ product.sku }}</span> - <span class="text-red-500">Rupture de stock</span>
        </template>
      </div>
      <div v-if="discountPercentage" class="absolute font-funny text-sky_orange text-white rounded-full" style="bottom: 10px; right: 10px;">
        -{{ discountPercentage }}%
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.sky__search-product-result {
  em {
    @apply font-bold not-italic;
  }
}
</style>

<script>
export default {
  props: {
    product: {
      required: true,
      type: Object
    }
  },
  computed: {
    discountPercentage () {
      const percentage = (this.product.crossed_price - this.product.price) / this.product.crossed_price * 100
      return Math.ceil(percentage) || null
    }
  }
}
</script>
