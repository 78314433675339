<template>
  <div class="relative" @click.stop>
    <div class="cursor-pointer select-none" @click="toggle">
      <div v-show="!show" class="icon-cart">
        <icon-cart style="width: 38px;" class="mx-auto icon-cart" />
      </div>
      <div v-show="show">
        <icon-nav-cross style="width: 38px;" class="mx-auto" />
      </div>
      <span v-show="!small" class="hidden xl:block"> Mon panier</span>
      <div class="text-xs absolute top-0 bg-sky_secondary text-white font-bold rounded-full flex items-center justify-center px-1" style="min-width: 20px; height: 20px; right: 0;">
        {{ $store.getters['cart/itemsCount'] }}
      </div>
    </div>
    <transition name="bounce">
      <div v-if="$store.state.cart.cart" v-show="show" class="sky__cart-popup" :class="{small}">
        <div class="bg-sky_blue text-white px-4 py-3 text-base">
          <span class="font-bold">Mon panier,</span> {{ $store.getters['cart/itemsCount'] }} article(s)
        </div>
        <div v-show="justAdded" class="text-success px-4 py-2 flex items-center" style="background: #DCEDC8;">
          <icon-check color="success" style="width: 25px; margin-right:5px;" />
          C'est dans votre panier!
        </div>
        <div class="sky__cart-items">
          <div
            v-for="item in $store.state.cart.cart.products"
            :key="item.pok"
            class="flex mb-3"
            style="height: 58px; overflow: hidden;"
          >
            <div
              class="bg-sky_grey_darker mr-4"
              :style="{
                width: '58px',
                height: '58px',
                backgroundSize: 'cover',
                backgroundImage: item.properties && item.properties.background ? `url(${item.properties.background})` : undefined
              }"
            >
              <img :src="item.cover + '?resize=true&width=58&height=58'" alt="">
            </div>
            <div class="flex-1 flex">
              <div class="flex-1">
                <nuxt-link :to="item.properties && item.properties.uri ? item.properties.uri : '/'" class="flex-1 font-bold text-sm leading-tight block mb-1" style="max-height: 34px; line-height: 17px; overflow: hidden">
                  {{ item.label }}
                </nuxt-link>
                <quantity-input
                  :item="item"
                  small
                />
              </div>
              <div class="pl-3 flex-1 flex items-center justify-between">
                <div class="text-xs">
                  {{ item.price }}€
                </div>
                <div class="cursor-pointer" style="width: 15px; height: 15px;" @click.stop="removeProductFromCart(item.sku)">
                  <img style="width: 15px;" src="../../../assets/icons/iconmonstr-trash-can-28.svg?img" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-5 text-sm" style="background: #ECEFF1;">
          <div class="flex justify-between items-center mb-1">
            <div class="font-bold">
              Sous-Total
            </div>
            <div>{{ $helpers.formatCurrency($store.getters['cart/productsTotal']) }}</div>
          </div>
          <div class="flex justify-between flex-wrap items-start">
            <sky-btn class="bg-white mb-2" border-color="white" @click="show = false">
              Fermer
            </sky-btn>
            <sky-btn
              class="bg-sky_blue text-white"
              @click="() => {
                if ($store.state.screen.width < 600) {
                  $router.push('/commande?valide')
                } else {
                  $router.push('/commande')
                }
                show = false
              }"
            >
              Commander
            </sky-btn>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import SkyBtn from '../../general/SkyBtn'
import IconCheck from '../../general/icons/IconCheck'
import QuantityInput from '../../general/QuantityInput'

export default {
  components: {
    SkyBtn,
    IconCheck,
    QuantityInput
  },
  props: {
    small: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      justAdded: false
    }
  },
  computed: {
    show: {
      get () {
        return this.$store.state.cart.show
      },
      set (val) {
        this.$store.commit('cart/setShow', val)
      }
    }
  },
  watch: {
    show (value) {
      if (value) {
        setTimeout(() => {
          document.addEventListener('click', this.onClickElseWhere)
        }, 200)
      } else {
        document.removeEventListener('click', this.onClickElseWhere)
      }
    },
    $route () {
      this.show = false
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.onClickElseWhere)
  },
  methods: {
    removeProductFromCart (sku) {
      this.$gtmEvents.onRemovedFromCart(sku, 'Petit Panier')
      return this.$store.dispatch('cart/removeProduct', { sku })
    },
    onClickElseWhere (e) {
      this.show = false
    },
    toggle () {
      this.show = !this.show
    }
  }
}
</script>

<style lang="scss" scoped>

.bounce-enter {
  transform: scale(0);
}

.bounce-leave-to {
  transform: scale(0);
}

.sky__cart-items {
  @apply p-4 flex-1 overflow-y-auto;
}

.sky__cart-popup {
  @apply bg-white fixed flex flex-col;
  opacity: 1;
  transition-duration: 0.2s;
  top: 50px;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 19999999;
  @screen lg {
    max-height: 600px;
    @apply absolute mt-4 shadow-xl;
    top: 100%;
    width: 365px;
    bottom: initial;
    left: initial;
  }
  &::after {
    @screen lg {
      @apply absolute block bg-sky_blue;
      content: '';
      right: 27px;
      top: -8px;
      width: 16px;
      height: 16px;
      transform: rotate(45deg);
    }
  }
  &.small {
    &::after {
      right: 12px;
    }
  }
}
</style>
