<template>
  <div class="flex">
    <icon-star-full
      v-for="i in Math.round(notation)"
      :key="i"
      style="width: 14px;height:14px;"
      class="mr-1"
    />
    <icon-star-empty
      v-for="i in max - Math.round(notation)"
      :key="'empty-' + i"
      style="width: 14px;height:14px;"
      class="mr-1"
    />
  </div>
</template>

<script>

export default {
  props: {
    notation: {
      required: true,
      type: Number
    },
    max: {
      required: false,
      type: Number,
      default: null
    }
  }
}
</script>
