<template>
  <icon-nav-arrow-right
    class="sky__icon"
    :class="{
      ['text-' + color]: true,
      left
    }"
  />
</template>

<script>
export default {
  props: {
    color: {
      required: false,
      default: 'sky_blue',
      type: String
    },
    left: {
      required: false,
      default: false,
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
  .sky__icon {
    path {
      stroke: currentColor;
      fill: transparent;
    }
    &.left {
      transform: rotate(180deg)
    }
  }
</style>
