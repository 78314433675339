import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ea799538 = () => interopDefault(import('../pages/auth.vue' /* webpackChunkName: "pages/auth" */))
const _18bb7736 = () => interopDefault(import('../pages/commande.vue' /* webpackChunkName: "pages/commande" */))
const _97731d1e = () => interopDefault(import('../pages/confirmation.vue' /* webpackChunkName: "pages/confirmation" */))
const _e7d6e246 = () => interopDefault(import('../pages/espace-pro.vue' /* webpackChunkName: "pages/espace-pro" */))
const _63fd88ca = () => interopDefault(import('../pages/kit.vue' /* webpackChunkName: "pages/kit" */))
const _292feb76 = () => interopDefault(import('../pages/magasins.vue' /* webpackChunkName: "pages/magasins" */))
const _188e2ec0 = () => interopDefault(import('../pages/mon-compte/index.vue' /* webpackChunkName: "pages/mon-compte/index" */))
const _4e717f22 = () => interopDefault(import('../pages/recherche.vue' /* webpackChunkName: "pages/recherche" */))
const _4d395cd2 = () => interopDefault(import('../pages/transaction.vue' /* webpackChunkName: "pages/transaction" */))
const _ceeffbb2 = () => interopDefault(import('../pages/trigger-error.vue' /* webpackChunkName: "pages/trigger-error" */))
const _353204e3 = () => interopDefault(import('../pages/validate-transaction.vue' /* webpackChunkName: "pages/validate-transaction" */))
const _6065a1aa = () => interopDefault(import('../pages/content/308-pressroom.vue' /* webpackChunkName: "pages/content/308-pressroom" */))
const _1df131f8 = () => interopDefault(import('../pages/mon-compte/mes-adresses.vue' /* webpackChunkName: "pages/mon-compte/mes-adresses" */))
const _6345c1d6 = () => interopDefault(import('../pages/mon-compte/mes-commandes.vue' /* webpackChunkName: "pages/mon-compte/mes-commandes" */))
const _570af13e = () => interopDefault(import('../pages/mon-compte/mes-infos.vue' /* webpackChunkName: "pages/mon-compte/mes-infos" */))
const _44f0a103 = () => interopDefault(import('../pages/mon-compte/mes-projets.vue' /* webpackChunkName: "pages/mon-compte/mes-projets" */))
const _670b0be2 = () => interopDefault(import('../pages/content/category/6-inspirations.vue' /* webpackChunkName: "pages/content/category/6-inspirations" */))
const _072f4668 = () => interopDefault(import('../pages/content/_slug.vue' /* webpackChunkName: "pages/content/_slug" */))
const _490e8dc6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ba32365a = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _ea799538,
    name: "auth"
  }, {
    path: "/commande",
    component: _18bb7736,
    name: "commande"
  }, {
    path: "/confirmation",
    component: _97731d1e,
    name: "confirmation"
  }, {
    path: "/espace-pro",
    component: _e7d6e246,
    name: "espace-pro"
  }, {
    path: "/kit",
    component: _63fd88ca,
    name: "kit"
  }, {
    path: "/magasins",
    component: _292feb76,
    name: "magasins"
  }, {
    path: "/mon-compte",
    component: _188e2ec0,
    name: "mon-compte"
  }, {
    path: "/recherche",
    component: _4e717f22,
    name: "recherche"
  }, {
    path: "/transaction",
    component: _4d395cd2,
    name: "transaction"
  }, {
    path: "/trigger-error",
    component: _ceeffbb2,
    name: "trigger-error"
  }, {
    path: "/validate-transaction",
    component: _353204e3,
    name: "validate-transaction"
  }, {
    path: "/content/308-pressroom",
    component: _6065a1aa,
    name: "content-308-pressroom"
  }, {
    path: "/mon-compte/mes-adresses",
    component: _1df131f8,
    name: "mon-compte-mes-adresses"
  }, {
    path: "/mon-compte/mes-commandes",
    component: _6345c1d6,
    name: "mon-compte-mes-commandes"
  }, {
    path: "/mon-compte/mes-infos",
    component: _570af13e,
    name: "mon-compte-mes-infos"
  }, {
    path: "/mon-compte/mes-projets",
    component: _44f0a103,
    name: "mon-compte-mes-projets"
  }, {
    path: "/content/category/6-inspirations",
    component: _670b0be2,
    name: "content-category-6-inspirations"
  }, {
    path: "/content/:slug?",
    component: _072f4668,
    name: "content-slug"
  }, {
    path: "/",
    component: _490e8dc6,
    name: "index"
  }, {
    path: "/*",
    component: _ba32365a,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
