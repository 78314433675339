import loadScript from 'load-script2'

export default (ctx, inject) => {
  let p = null
  inject('paypal', {
    get () {
      if (p === null) {
        // eslint-disable-next-line no-async-promise-executor
        p = new Promise(async (resolve, reject) => {
          try {
            await loadScript(`https://www.paypal.com/sdk/js?client-id=${ctx.store.state.ui.paypalClientId}&currency=EUR`, {
              'data-sdk-integration-source': 'button-factory'
            })
            // eslint-disable-next-line no-undef
            resolve(paypal)
          } catch (error) {
            reject(error)
          }
        })
      }
      return p
    }
  })
}
