<template>
  <footer class="pt-8">
    <home-reinsurance class="mb-10" />
    <div class="bg-sky_grey_darker py-8">
      <div class="container mx-auto lg:flex">
        <div class="lg:w-1/4 px-8 mb-4">
          <icon-logo class="mb-5" />
          <div class="uppercase flex items-center p-2 justify-between" style="background: #e9e9e4;">
            <div class="flex items-center">
              <icon-france style="width: 31px;" class="mr-3" />
              <span class="text-xs uppercase font-semibold">
                Skylantern France
              </span>
            </div>
            <img style="width: 25px;" src="../../assets/icons/nav-select.svg?img" alt="Check">
          </div>
         <!-- <a href="https://www.skylantern-original.co.uk" target="_blank" class="uppercase flex items-center p-2">
            <icon-uk style="width: 31px;" class="mr-3" />
            <span class="text-xs uppercase font-semibold">
              Skylantern UK
            </span>
          </a>
          -->
        </div>
        <div class="lg:w-1/4 px-8 mb-4">
          <h3 class="font-bold text-xl mb-4">
            Suivez-nous !
          </h3>
          <div class="flex justify-between">
            <a href="https://www.facebook.com/SkyLantern.FR" target="_blank"><icon-facebook style="width: 40px;height:40px;" /></a>
            <a href="https://www.instagram.com/skylantern.fr/" target="_blank"><icon-instagram style="width: 40px;height:40px;" /></a>
            <a href="https://twitter.com/skylantern_fr?lang=fr" target="_blank"><icon-twitter style="width: 40px;height:40px;" /></a>
            <a href="https://www.pinterest.fr/skylantern/" target="_blank"><icon-pinterest style="width: 40px;height:40px;" /></a>
            <a href="https://www.youtube.com/channel/UCcRDha6inzRbeevaKocOLUA" target="_blank"><icon-youtube style="width: 40px;height:40px;" /></a>
          </div>
        </div>
        <div class="lg:w-1/4 px-8 mb-4">
          <h3 class="font-bold text-xl mb-4">
            Nous contacter
          </h3>
          <div class="text-sm">
            <div>
              +33 (0)3 66 72 85 22
            </div>
            <div class="mb-3">
              info@skylantern.fr
            </div>
            <div>
              SkyLantern est une marque RUBEE COMPANY
              <br>
              2 allée de la Haye du Temple
              <br>
              Euratechnologies
              <br>
              59160 LOMME
            </div>
          </div>
        </div>
        <div class="lg:w-1/4 px-8 mb-4">
          <h3 class="font-bold text-xl mb-4">
            A Propos
          </h3>
          <div class="text-sm">
            <ul>
              <li>
                <nuxt-link to="/content/4-qui-sommes-nous" class="py-1">
                  Qui sommes-nous
                </nuxt-link>
              </li>
              <li class="py-1">
                <nuxt-link to="/content/3-conditions-generales-de-vente" class="py-1">
                  Conditions générales de Vente
                </nuxt-link>
              </li>
              <li class="py-1">
                <nuxt-link to="/content/2-mentions-legales" class="py-1">
                  Mentions légales
                </nuxt-link>
              </li>
              <li class="py-1">
                <nuxt-link to="/content/55-politique-antispam" class="py-1">
                  Politique Antispam
                </nuxt-link>
              </li>
              <li class="py-1">
                <nuxt-link to="/content/308-pressroom" class="py-1">
                  Contact Presse
                </nuxt-link>
              </li>
              <li class="py-1">
                <nuxt-link to="/content/category/6-inspirations" class="py-1">
                  Idée Design
                </nuxt-link>
              </li>
              <li class="py-1">
                <nuxt-link to="/content/4-qui-sommes-nous" class="py-1">
                  Skylantern Original in the UK
                </nuxt-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-sky_blue hidden lg:block">
      <div class="container mx-auto text-white pt-4 pb-4">
        <div class="font-funny text-center text-4xl mb-3">
          On parle de Skylantern sur:
        </div>
        <ul class="flex justify-center">
          <li
            v-for="pressItem in pressItems"
            :key="pressItem.alt"
            class="mx-4"
          >
            <a :href="pressItem.link" target="_blank">
              <img :src="pressItem.img" :alt="pressItem.alt">
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import HomeReinsurance from './HomeReinsurance'

export default {
  components: {
    HomeReinsurance
  },
  data () {
    return {
      pressItems: [
        {
          img: require('../../assets/footer-press/capital.png'),
          link: 'https://www.skylantern.fr/blog/skylantern-capital-magazine/',
          alt: 'Capital'
        },
        {
          img: require('../../assets/footer-press/tf1.png'),
          link: 'https://www.skylantern.fr/blog/le-jt-de-tf1-invite-skylantern/',
          alt: 'TF1'
        },
        {
          img: require('../../assets/footer-press/le_monde.png'),
          link: 'https://www.skylantern.fr/blog/skylantern-dans-le-monde/',
          alt: 'Le Monde'
        },
        {
          img: require('../../assets/footer-press/europe_1.png'),
          link: 'https://www.skylantern.fr/blog/skylantern-sur-europe-1/',
          alt: 'Europe 1'
        },
        {
          img: require('../../assets/footer-press/la_voix_du_nord.png'),
          link: 'https://www.skylantern.fr/blog/2010/08/',
          alt: 'La voix du nord'
        },
        {
          img: require('../../assets/footer-press/rtl.png'),
          link: 'https://www.skylantern.fr/blog/skylantern-sur-rtl-31082010-les-perles-dagnes/',
          alt: 'RTL'
        },
        {
          img: require('../../assets/footer-press/madame_figaro.png'),
          link: 'https://www.skylantern.fr/blog/des-idees-pour-un-mariage-original/',
          alt: 'Madame Figaro'
        },
        {
          img: require('../../assets/footer-press/marie_claire.png'),
          link: 'https://www.skylantern.fr/blog/marie-claire/',
          alt: 'Marie Claire'
        },
        {
          img: require('../../assets/footer-press/femme_actuelle.png'),
          link: 'https://www.skylantern.fr/blog/skylantern-dans-femme-actuelle/',
          alt: 'Femme Actuelle'
        },
        {
          img: require('../../assets/footer-press/mariee.png'),
          link: 'https://www.skylantern.fr/blog/idees-tendances-skylantern/',
          alt: 'Mariee'
        }
      ]
    }
  }
}
</script>
