<template>
  <div class="flex" :class="small ? 'small' : ''">
    <button
      class="update-quantity-input"
      @click="addQuantity(pricing.pcb * -1)"
    >
      -
    </button>
    <div
      :class="small ? 'text-xs border-b border-t' : 'font-bold text-sm py-1 border-t-2 border-b-2'"
      class="border-sky_grey_darker text-center"
      :style="{
        width: small ? '30px' : '50px'
      }"
    >
      {{ item.quantity }}
    </div>
    <div class="update-quantity-input" @click="addQuantity(pricing.pcb)">
      +
    </div>
  </div>
</template>

<script>

export default {
  props: {
    item: {
      required: true,
      type: Object
    },
    small: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    pricing () {
      const informations = this.$store.state.cart.cart._products.find(p => p.sku === this.item.sku)
      return informations.pricing
    }
  },
  methods: {
    async addQuantity (quantity) {
      this.addingToCart = quantity
      try {
        const informations = this.$store.state.cart.cart._products.find(p => p.sku === this.item.sku)
        if (!informations) {
          alert('Ce produit est inconnu.')
        }
        await this.$store.dispatch('cart/addProduct', {
          sku: this.item.sku,
          label: this.item.label,
          cover: this.item.cover,
          pricing: informations.pricing,
          quantity
        })
      } catch (error) {
        alert('Une erreur est survenue...')
        console.error(error)
      }
      this.addingToCart = null
    }
  }
}
</script>

<style lang="scss" scoped>
.update-quantity-input {
  @apply px-2 border-2 font-bold cursor-pointer border-sky_grey_darker select-none text-lg flex items-center;
  &:hover {
    @apply border-sky_blue;
  }
  &:focus {
    outline: none;
  }
}
.small {
  .update-quantity-input {
    @apply text-xs border;
  }
}
</style>
