<template>
  <icon-nav-check
    class="sky__icon"
    :class="'text-' + color"
  />
</template>

<style lang="scss">
  .sky__icon {
    path {
      stroke: currentColor;
    }
  }
</style>

<script>
export default {
  props: {
    color: {
      required: false,
      default: 'sky_blue',
      type: String
    }
  }
}
</script>
