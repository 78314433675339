<template>
  <div class="sky__checkbox flex items-center cursor-pointer select-none" @click="toggleValue">
    <div
      :style="{
        width: '18px',
        height: '18px'
      }"
      class="bg-sky_grey"
      :class="{
        'bg-sky_grey': !internalValue,
        'bg-sky_secondary': internalValue
      }"
    >
      <icon-check v-if="internalValue" style="width: 25px; position: relative; left: -4px; top: -3px;" color="white" />
    </div>
    <div class="ml-2" :class="xxs ? 'text-xs' : 'text-sm'">
      <slot name="label">
        {{ label }}
      </slot>
    </div>
  </div>
</template>

<script>
import IconCheck from '../general/icons/IconCheck'
export default {
  components: {
    IconCheck
  },
  props: {
    value: {
      required: false,
      default: null,
      type: [Boolean, Array]
    },
    referenceValue: {
      required: false,
      default: null,
      type: [String, Object, Array, Number]
    },
    label: {
      required: false,
      default: null,
      type: String
    },
    xxs: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data () {
    const internalValue = typeof this.value === 'boolean' ? this.value : undefined
    return {
      internalValue
    }
  },
  watch: {
    internalValue (val) {
      if (Array.isArray(this.value)) {
        if (val && this.referenceValue && !this.value.includes(this.referenceValue)) {
          this.$emit('input', [...this.value, this.referenceValue])
        } else if (val && this.referenceValue && this.value.includes(this.referenceValue)) {
          const copy = [...this.value]
          const index = copy.indexOf(this.referenceValue)
          copy.splice(index, 1)
          this.$emit('input', copy)
        }
      } else if (val !== this.value) {
        this.$emit('input', val)
      }
    },
    value (val) {
      if (!Array.isArray(val) && typeof val === 'boolean' && val !== this.internalValue) {
        this.internalValue = val
      }
    }
  },
  methods: {
    toggleValue () {
      this.internalValue = !this.internalValue
    }
  }
}
</script>
