<template>
  <div class="container mx-auto text-center">
    <div v-if="error.statusCode === 404">
      <h1 class="py-6 text-4xl font-funny py-6">
        Cette page s'est envolée avec nos lanternes!
      </h1>
      <sky-btn to="/" class="mb-5">
        Retour à l'accueil
      </sky-btn>
      <img class="block mx-auto" style="max-width: 500px;" src="@/assets/undraw_blank_canvas_3rbb.svg?img" alt="Erreur 404">
    </div>
    <div v-else-if="error.message === 'Network Error'">
      <h1 class="py-6 text-4xl font-funny py-6">
        Vous avez l'air d'être hors-ligne.
      </h1>
      <sky-btn @click="reload">
        Recharger la page
      </sky-btn>
    </div>
    <div v-else>
      <h1 class="py-6 text-4xl font-funny py-6">
        Une erreur inconnue est survenue... Rechargez la page dans une minute.
      </h1>
      <sky-btn class="mb-5" @click="reload">
        Recharger la page
      </sky-btn>
      <img class="block mx-auto" style="max-width: 500px;" src="@/assets/undraw_blank_canvas_3rbb.svg?img" alt="Erreur 500">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      required: true,
      type: Object
    }
  },
  mounted () {
    this.$gtmEvents.onErrorPage(this.error.statusCode, this.$route.fullPath, this.error.statusCode === 404 ? '' : this.error.message)
  },
  methods: {
    reload () {
      window.location.reload()
    }
  }
}
</script>
