<template>
  <div
    v-wave
    class="inline-block border-2"
    :class="{
      'rounded-full': rounded,
      ['border-' + borderColor]: true
    }"
  >
    <clickable
      v-bind="$attrs"
      :to="to"
      :href="href"
      class="sky__btn"
      :class="{dark, small}"
      :loading="loading"
      :disabled="disabled || loading"
      v-on="$listeners"
    >
      <span class="flex-1 block relative">
        <span v-if="loading" class="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center block">
          <pulse-loader size="5px" loading color="white" />
        </span>
        <span
          :style="{
            opacity: loading ? 0 : 1
          }"
        >
          <slot />
        </span>
      </span>
      <template v-if="arrowRight">
        <span
          class="block ml-3"
          :class="{
            ['text-' + arrowColor]: true
          }"
        >
          <icon-nav-arrow-right style="width: 12px;" class="arrow stroke-current" />
        </span>
      </template>
      <slot name="append" />
    </clickable>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Clickable from './Clickable'

export default {
  components: {
    PulseLoader,
    Clickable
  },
  props: {
    to: {
      required: false,
      type: [String, Object],
      default: null
    },
    href: {
      required: false,
      type: String,
      default: null
    },
    arrowRight: {
      required: false,
      type: Boolean,
      default: false
    },
    arrowColor: {
      required: false,
      type: String,
      default: 'black'
    },
    dark: {
      required: false,
      type: Boolean,
      default: false
    },
    rounded: {
      required: false,
      type: Boolean,
      default: false
    },
    small: {
      required: false,
      type: Boolean,
      default: false
    },
    loading: {
      required: false,
      type: Boolean,
      default: false
    },
    borderColor: {
      required: false,
      type: String,
      default: 'sky_blue'
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        click (e) {
          if (!this.loading) {
            this.$emit('click', e)
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .sky__btn {
    padding: 12px 20px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    @apply tracking-widest flex items-center w-full select-none;
    &:focus {
      @apply outline-none;
    }
    &:disabled {
      cursor: not-allowed;
    }
    .arrow {
      path {
        stroke: currentColor;
        fill: transparent;
      }
    }
    &.dark {
      .arrow {
        path {
          stroke: white;
          fill: transparent;
        }
      }
    }
    &.small {
      padding: 6px 10px;
    }
  }
</style>
