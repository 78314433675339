import storage from 'local-storage-fallback'
export default {
  state () {
    return {
      userId: null,
      initiated: false,
      email: null,
      displayName: null,
      avatar: null,
      preferences: null
    }
  },
  mutations: {
    setUserId (state, userId) {
      state.userId = userId
    },
    setEmail (state, email) {
      state.email = email
    },
    setDisplayName (state, displayName) {
      state.displayName = displayName
    },
    setAvatar (state, avatar) {
      state.avatar = avatar
    },
    init (state) {
      state.initiated = true
    },
    setPreferences (state, preferences) {
      state.preferences = preferences
    }
  },
  actions: {
    init ({ commit, dispatch, state }, customToken) {
      if (!state.initiated) {
        return new Promise((resolve) => {
          let resolved = false
          this.$fire.auth.onAuthStateChanged(async (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              await dispatch('fetchUserPreferences')
              commit('setUserId', user.uid)
              commit('setEmail', user.email)
              commit('setAvatar', user.photoURL)
              commit('setDisplayName', user.displayName)
            } else {
              commit('setUserId', null)
              commit('setEmail', null)
              commit('setAvatar', null)
              commit('setDisplayName', null)
            }
            commit('init')
            if (!resolved) {
              resolved = true
              resolve(user)
            }
          })
        })
      }
    },
    async refreshUser ({ commit }) {
      const user = await this.$fire.auth.currentUser
      if (user) {
        await user.reload()
        const refreshedUser = await this.$fire.auth.currentUser
        commit('setAvatar', refreshedUser.photoURL)
        commit('setDisplayName', refreshedUser.displayName)
      }
    },
    async disconnect ({ commit }) {
      await this.$fire.auth.signOut()
      commit('setUserId', null)
      commit('setEmail', null)
      commit('setAvatar', null)
      commit('setDisplayName', null)
      commit('setPreferences', null)
      storage.removeItem('yellaw-cart-id')
      window.location.reload()
    },
    async fetchUserPreferences ({ commit }) {
      const { data: preferences } = await this.$api.get('/me/preferences')
      commit('setPreferences', preferences)
    }
  },
  getters: {
    isLoggedIn (state) {
      return !!state.userId
    },
    paysTaxes (state) {
      if (!state.preferences) {
        return true
      }
      return state.preferences.pays_taxes
    }
  }
}
