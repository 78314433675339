<template>
  <nav
    ref="sky-menu"
    class="
      sky__navigation-menu
    "
    :class="{
      active: showMobileMenu
    }"
    @mouseleave="() => {
      if ($store.state.screen.width > 1024) {
        firstLevelSelectedForFirstTime = true
        $store.commit('ui/HIDE_MOBILE_MENU')
      }
    }"
  >
    <div class="first-level-menu">
      <ul class="first-level-list">
        <li
          v-for="(item, firstLevelIndex) in menu.items"
          :key="'first-level-' + firstLevelIndex"
          class="first-level-list-item"
        >
          <menu-item
            ref="firstLevelListItem"
            :item="item"
            class="menu-item-content first-level-list-item-content"
            :class="firstLevelSelected === firstLevelIndex ? 'menu-open' : ''"
            :style="{
              color: firstLevelSelected === firstLevelIndex ? item.colorSelected : item.textColor,
              backgroundColor: firstLevelSelected === firstLevelIndex ? item.backgroundSelected : item.backgroundColor
            }"
            @open="() => {
              firstLevelSelected = firstLevelIndex
              $store.commit('ui/SHOW_MOBILE_MENU')
            }"
            @mouseover="firstLevelMouseOverSelected(firstLevelIndex)"
          >
            <div>
              <img v-if="item.picto" loading="lazy" class="sky__menu-picto" :src="item.picto" alt="">
              {{ item.text }}
            </div>
            <component
              :is="require('../../../assets/icons/nav-select-down.svg')"
              v-if="item.items && item.items.length"
              class="ml-2 sky__navigation-arrow"
              style="width: 10px;height:10px;"
              alt=""
            />
          </menu-item>
          <template
            v-if="item.items"
          >
            <div
              class="second-level-menu-wrapper"
            >
              <transition name="second-level-transition">
                <div
                  v-show="showMobileMenu && firstLevelSelected === firstLevelIndex"
                  class="mobile-menu second-level-menu"
                  :class="{
                    'first-activated': firstLevelSelectedForFirstTime
                  }"
                >
                  <!-- Barre de fond de Catégorie de Niveau 1 -->
                  <div class="hidden lg:block flex-1 bg-sky_grey_darker" />
                  <div class="small-container lg:bg-sky_grey_darker">
                    <div
                      :style="{
                        height: '30px',
                        width: '240px',
                        background: 'red',
                        position: 'absolute',
                        transform: `translateY(${30 * (secondLevelSelected || 0)}px)`,
                        transitionDuration: '0.0s',
                        zIndex: 0,
                        background: '#E8E1CD'
                      }"
                      class="hidden lg:block mt-4"
                    />
                    <div class="lg:flex lg:relative z-0">
                      <ul class="second-level-menu-list">
                        <li>
                          <a
                            href="#"
                            class="bg-sky_secondary block w-full lg:hidden text-white text-xs py-2 px-4"
                            @click.prevent="firstLevelSelected = null"
                          >
                            Retour
                          </a>
                        </li>
                        <li
                          v-for="(secondLevelItem, secondLevelIndex) in item.items"
                          :key="'second-level-' + secondLevelIndex"
                          class="second-level-menu-list-item"
                        >
                          <template
                            v-if="secondLevelItem.to && !secondLevelItem.items"
                          >
                            <nuxt-link
                              class="menu-item-content second-level-list-item-content"
                              :to="secondLevelItem.to"
                            >
                              <div>
                                <img v-if="secondLevelItem.picto" loading="lazy" class="sky__menu-picto" :src="secondLevelItem.picto" alt="">
                                {{ secondLevelItem.text }}
                              </div>
                            </nuxt-link>
                          </template>
                          <template v-else>
                            <a
                              class="menu-item-content second-level-list-item-content"
                              href="#"
                              @click.prevent="() => {
                                secondLevelSelected = secondLevelIndex
                                if ($store.state.screen.width > 900 && secondLevelItem.to) {
                                  $router.push(secondLevelItem.to)
                                }
                              }"
                              @mouseover="() => {
                                if ($store.state.screen.width > 900) {
                                  secondLevelSelected = secondLevelIndex
                                }
                              }"
                            >
                              <div>
                                <img v-if="secondLevelItem.picto" loading="lazy" class="sky__menu-picto" :src="secondLevelItem.picto" alt="">
                                {{ secondLevelItem.text }}
                              </div>
                              <component
                                :is="require('../../../assets/icons/nav-select-down.svg')"
                                v-if="secondLevelItem.items && secondLevelItem.items.length"
                                class="ml-2 sky__navigation-arrow"
                                style="width: 10px;height:10px;"
                                alt=""
                              /></a>
                          </template>
                          <template
                            v-if="secondLevelItem.items"
                          >
                            <div
                              class="third-level-menu-wrapper"
                            >
                              <transition name="third-level-transition">
                                <div
                                  v-show="secondLevelSelected === secondLevelIndex"
                                  class="mobile-menu third-level-menu"
                                >
                                  <ul class="third-level-menu-list">
                                    <li class="order-first">
                                      <a
                                        href="#"
                                        class="bg-sky_secondary block w-full lg:hidden text-white text-xs py-2 px-4"
                                        @click.prevent="secondLevelSelected = null"
                                      >
                                        Retour
                                      </a>
                                    </li>
                                    <li
                                      v-for="(thirdLevelItem, thirdLevelIndex) in secondLevelItem.items"
                                      :key="'third-level-' + thirdLevelIndex"
                                      class="third-level-menu-list-item"
                                    >
                                      <template
                                        v-if="thirdLevelItem.to"
                                      >
                                        <nuxt-link
                                          class="menu-item-content third-level-list-item-content"
                                          :class="$route.path === thirdLevelItem.to ? 'active' : ''"
                                          :to="thirdLevelItem.to"
                                        >
                                          <div>
                                            <img v-if="thirdLevelItem.picto" loading="lazy" class="sky__menu-picto" :src="thirdLevelItem.picto" alt="">
                                            {{ thirdLevelItem.text }}
                                          </div>
                                        </nuxt-link>
                                      </template>
                                      <template v-else>
                                        <a
                                          class="menu-item-content third-level-list-item-content"
                                          href="#"
                                          @click.prevent="thirdLevelSelected = thirdLevelIndex"
                                        >
                                          <div>
                                            <img v-if="thirdLevelItem.picto" loading="lazy" class="sky__menu-picto" :src="thirdLevelItem.picto" alt="">
                                            {{ thirdLevelItem.text }}
                                          </div>
                                        </a>
                                      </template>
                                      <template v-if="thirdLevelItem.items && thirdLevelItem.items.length">
                                        <ul>
                                          <li
                                            v-for="(fourthLevelItem, fourthLevelIndex) in thirdLevelItem.items"
                                            :key="'fourth-level-' + fourthLevelIndex"
                                            class="fourth-level-menu-list-item"
                                          >
                                            <template
                                              v-if="fourthLevelItem.to"
                                            >
                                              <nuxt-link
                                                class="menu-item-content fourth-level-list-item-content"
                                                :class="$route.path.indexOf(fourthLevelItem.to) !== -1 ? 'active' : ''"
                                                :to="fourthLevelItem.to"
                                              >
                                                {{ fourthLevelItem.text }}
                                              </nuxt-link>
                                            </template>
                                            <template v-else>
                                              <a
                                                class="menu-item-content fourth-level-list-item-content"
                                                href="#"
                                                @click.prevent
                                              >
                                                {{ fourthLevelItem.text }}
                                              </a>
                                            </template>
                                          </li>
                                        </ul>
                                      </template>
                                    </li>
                                    <li class="order-first lg:order-last third-level-menu-list-item lg:block">
                                      <nuxt-link
                                        v-if="secondLevelItem.to"
                                        class="font-bold text-xs tracking-widest py-4 px-2 uppercase flex justify-start lg:pb-10"
                                        :to="secondLevelItem.to"
                                      >
                                        Tous les produits
                                        <icon-arrow class="ml-2" color="sky_secondary" style="width: 12px; height:17px;" />
                                      </nuxt-link>
                                    </li>
                                  </ul>
                                </div>
                              </transition>
                            </div>
                          </template>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="hidden lg:block flex-1 bg-white relative"
                  >
                    <transition name="desktop-menu-img-transition">
                      <div
                        v-if="firstLevelSelected !== null && secondLevelSelected !== null && menu.items[firstLevelSelected].items && menu.items[firstLevelSelected].items[secondLevelSelected]"
                        :key="secondLevelSelected"
                        class="absolute right-0 top-0 bottom-0 flex items-center"
                        :style="{
                          backgroundColor: menu.items[firstLevelSelected].items[secondLevelSelected].sliderBackgroundColor || '#06132F',
                          backgroundImage: menu.items[firstLevelSelected].items[secondLevelSelected].sliderBackgroundImage || undefined,
                          backgroundSize: 'cover',
                          left: '-140px'
                        }"
                      >
                        <img v-if="menu.items[firstLevelSelected].items[secondLevelSelected].sliderPicture" style="width: 350px; transform: translateX(-50%)" :src="menu.items[firstLevelSelected].items[secondLevelSelected].sliderPicture || require('../../../assets/fixtures/lanternes-volantes.png')" alt="">
                      </div>
                    </transition>
                  </div>
                </div>
              </transition>
            </div>
          </template>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import menu from '../../../fixtures/menu'
import IconArrow from '../../general/icons/IconArrow'
import MenuItem from './MenuItem'

export default {
  components: {
    MenuItem,
    IconArrow
  },
  data () {
    return {
      firstLevelSelected: null,
      secondLevelSelected: null,
      thirdLevelSelected: null,
      menu,
      firstLevelSelectedForFirstTime: true
    }
  },
  computed: {
    showMobileMenu () {
      return this.$store.state.ui.showMobileMenu
    }
  },
  watch: {
    firstLevelSelected (value, oldValue) {
      if (value && oldValue) {
        this.firstLevelSelectedForFirstTime = false
      }

      if (value === null) {
        this.firstLevelSelectedForFirstTime = true
      }

      if (value !== null && this.$store.state.screen.width >= 1024 && menu.items[value].items?.length) {
        this.secondLevelSelected = 0
        this.$store.commit('ui/SHOW_MOBILE_MENU')
      }

      if (oldValue === null) {
        setTimeout(() => {
          this.firstLevelSelectedForFirstTime = false
        }, 400)
      }
    },
    '$route.fullPath' (to, from) {
      this.$store.commit('ui/HIDE_MOBILE_MENU')
    },
    showMobileMenu (val) {
      if (val) {
        document.addEventListener('click', this.screenEventListener)
      } else {
        document.removeEventListener('click', this.screenEventListener)
      }
    }
  },
  methods: {
    screenEventListener (e) {
      // const isDescendant = (p, c) => {
      //   while ((c = c.parentNode) && c !== p) {
      //     if (c.parentNode.classList.includes('sky__navigation-menu')) {
      //       return true
      //     }
      //   }
      //   // alert('final: ' + c)
      //   return false
      // }
      //
      // if (this.$refs['sky-menu'] && !isDescendant(this.$refs['sky-menu'], e.target)) {
      //   this.firstLevelSelectedForFirstTime = true
      //   this.$store.commit('ui/HIDE_MOBILE_MENU')
      //   setTimeout(() => {
      //     this.firstLevelSelected = null
      //     this.secondLevelSelected = null
      //     this.thirdLevelSelected = null
      //   }, 300)
      // }
    },
    firstLevelMouseOverSelected (index) {
      if (this.$store.state.screen.width > 900) {
        setTimeout(() => {
          if (this.$refs.firstLevelListItem[index].$el.matches(':hover')) {
            this.firstLevelSelected = index
            this.$store.commit('ui/SHOW_MOBILE_MENU')
          }
        }, 300)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .small-container {
    max-width: 100%;
    @screen lg {
      width: 1180px;
    }
  }
  .sky__navigation-menu {
    background: white;
    @apply fixed bottom-0 left-0;
    width: 300px;
    max-width: 100%;
    top: 50px;
    transform: translateX(-100%);
    transition-duration: 0.3s;
    overflow: hidden;
    @screen lg {
      @apply static;
      overflow: visible;
      transform: translateX(0);
      width: initial;
      background: transparent;
    }
    &.active {
      transform: translateX(0);
      @screen lg {
        @apply static;
      }
    }
    .menu-item-content {
      @apply w-full bg-sky_grey_darker py-3 px-4 text-sm font-bold flex justify-between;
      margin-bottom: 2px;
      @screen lg {
        // @apply bg-white;
        width: initial;
        margin-bottom: initial;
        padding: initial;
        font-weight: initial;
        background: transparent;
      }
      .sky__menu-picto {
        @apply mr-3 rounded-full;
        margin-top: -13px;
        margin-bottom: -13px;
        display: inline-block;
        width: 30px;
        @screen lg {
          @apply hidden;
        }
      }
      .sky__navigation-arrow {
        transform: rotate(-90deg);
        @screen lg {
          @apply hidden;
          // transform: initial;
        }
      }
    }
    .mobile-menu {
      @apply absolute top-0 left-0 right-0 bottom-0 bg-white overflow-auto z-10;
      &.second-level-transition-enter-active {
        animation: menu-mobile-animation 0.3s;
      }
      &.second-level-transition-leave-active {
        animation: menu-mobile-animation 0.3s reverse;
      }
      &.third-level-transition-enter-active {
        animation: menu-mobile-animation 0.3s;
      }
      &.third-level-transition-leave-active {
        animation: menu-mobile-animation 0.3s reverse;
      }
      @screen lg {
        @apply overflow-visible;
      }
    }
    .first-level-list {
      @screen lg {
        @apply mx-auto flex justify-center border-t;
      }
      .first-level-list-item {
        .first-level-list-item-content {
          @screen lg {
            @apply px-2 py-3 font-bold text-sm;
          }
          @screen xl {
            @apply px-5;
          }
          &.menu-open {
            @screen lg {
              @apply text-sky_secondary;
              box-shadow: inset 0px -3px 0px 0px rgba(0,151,167,1);
            }
          }
        }
      }
    }
    .second-level-menu-wrapper {
      @screen lg {
        position: absolute;
        left: 0;
        right: 0;
        overflow: hidden;
        background: transparent;
      }
      .second-level-menu {
        @screen lg {
          @apply static flex overflow-hidden mb-3 border-t;
          background: white;
          box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.3);
          &.second-level-transition-enter-active {
            animation: menu-animation 0s;
          }
          &.second-level-transition-leave-active {
            animation: menu-animation 0s reverse;
          }
          &.second-level-transition-enter-active.first-activated {
            animation: menu-animation 0.4s;
          }
          &.second-level-transition-leave-active.first-activated {
            animation: menu-animation 0.4s reverse;
          }

          .desktop-menu-img-transition-enter-active {
            z-index: 10;
            animation: menu-img-animation 0.4s;
          }
          .desktop-menu-img-transition-leave-active {
            z-index: 9;
            animation: menu-img-animation 0.2s reverse;
          }
        }
        .second-level-menu-list {
          @screen lg {
            @apply py-4;
            width: 255px;
            min-height: 400px;
          }
          .second-level-list-item-content {
            @screen lg {
              @apply uppercase tracking-widest text-xs px-5 font-semibold;
              padding-top: 7px;
              padding-bottom: 7px;
              background: transparent;
            }
          }
        }
      }
    }
    .third-level-menu-wrapper {
      @screen lg {
        @apply px-12 py-5 bg-white;
        left: 255px;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
      }
      .third-level-menu {
        @screen lg {
          @apply relative z-10;
          &.second-level-transition-enter-active {
            animation: initial;
          }
          &.second-level-transition-leave-active {
            animation: initial;
          }
          &.third-level-transition-enter-active {
            animation: initial;
          }
          &.third-level-transition-leave-active {
            animation: initial;
          }
        }
        .third-level-menu-list {
          @apply flex flex-col;
          @screen lg {
            @apply flex flex-col flex-wrap content-start;
            height: 400px;
          }
        }
        .third-level-menu-list-item {
          @screen lg {
            @apply mb-3;
            width: 270px;
            margin-bottom:1em;
          }
        }
        .third-level-list-item-content {
          &:hover {
            text-decoration: underline;
          }
          @screen lg {
            @apply font-bold mb-2;
          }
          &.active {
            @screen lg {
              @apply text-sky_secondary;
            }
          }
        }
      }
    }
    .fourth-level-list-item-content {
      &:hover {
        text-decoration: underline;
      }
      @apply text-xs tracking-wide mb-1 bg-white;
      &.active {
        @screen lg {
          @apply text-sky_secondary relative;
        }
      }
    }
  }

  @keyframes menu-animation {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes menu-mobile-animation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes menu-img-animation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
</style>
