var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"desktop-fixed-bar",staticClass:"sky__desktop-fixed-bar bg-white",class:{
      'fixed': true,
      'lg:fixed': _vm.isScrolled,
      'lg:static': !_vm.isScrolled,
      'left-0': true,
      'right-0': true,
      'top-0': true
    }},[_c('div',{staticClass:"sky__bar items-center justify-center pb-2 hidden lg:flex z-20 relative bg-white",class:{
        'is-scrolled': _vm.isMenuSmall
      }},[_c('div',{staticClass:"container items-end flex",class:_vm.$store.state.screen.ordering ? 'items-center' : 'items-end'},[(!_vm.$store.state.screen.ordering)?_c('div',{staticClass:"sky__search flex flex-col justify-end flex-1"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMenuSmall),expression:"!isMenuSmall"}],staticClass:"text-center text-sky_blue text-xl font-funny md:text-xl lg:text-2xl xl:text-3xl",staticStyle:{"height":"45px","overflow":"hidden","position":"relative","top":"5px"}},[_vm._v("\n            Créateur d'enchantement depuis 2007\n          ")]),_vm._v(" "),_c('global-search-component',{attrs:{"small":_vm.isMenuSmall}})],1):_vm._e(),_vm._v(" "),_c('nuxt-link',{staticClass:"sky__logo block text-center flex-1",attrs:{"to":"/"}},[_c('icon-logo',{staticClass:"logo",class:{
              'is-scrolled': _vm.isMenuSmall,
              'mx-auto': !_vm.$store.state.screen.ordering
            },style:({
              'transform': _vm.$store.state.screen.ordering ? 'scale(0.7)' : 'scale(1)',
              position: 'relative',
              top: '4px'
            })})],1),_vm._v(" "),(!_vm.$store.state.screen.ordering)?_c('div',{staticClass:"sky__nav flex-1 flex align-end justify-end text-xs"},[_c('nuxt-link',{staticClass:"sky__nav-item",attrs:{"to":"/magasins"}},[_c('icon-stores',{staticClass:"mx-auto",staticStyle:{"width":"38px"}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMenuSmall),expression:"!isMenuSmall"}],staticClass:"hidden xl:block"},[_vm._v("Magasins")])],1),_vm._v(" "),_c('nuxt-link',{staticClass:"sky__nav-item",attrs:{"to":"/mon-compte/mes-projets"}},[_c('icon-projects',{staticClass:"mx-auto",staticStyle:{"width":"38px"}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMenuSmall),expression:"!isMenuSmall"}],staticClass:"hidden xl:block"},[_vm._v("Mes projets")])],1),_vm._v(" "),_c('nuxt-link',{staticClass:"sky__nav-item",attrs:{"to":"/mon-compte"}},[_c('icon-account',{staticClass:"mx-auto",staticStyle:{"width":"38px"}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMenuSmall),expression:"!isMenuSmall"}],staticClass:"hidden xl:block"},[_vm._v("Mon compte")])],1),_vm._v(" "),_c('my-cart-button',{staticClass:"sky__nav-item",attrs:{"small":_vm.isMenuSmall}})],1):(!_vm.isMenuSmall)?_c('div',{staticClass:"text-right"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"font-bold text-sm"},[_vm._v("\n            Du lundi au vendredi au\n          ")]),_vm._v(" "),_c('div',{staticClass:"font-bold text-sm"},[_vm._v("\n            +33 (0)3 66 72 85 22\n          ")])]):_vm._e()],1)]),_vm._v(" "),(!_vm.$store.state.screen.ordering)?_c('div',{ref:"sky-menu"},[_c('sky-menu')],1):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"bg-white hidden lg:block",style:({
      height: _vm.totalFixedHeight + 'px'
    })})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-funny text-2xl flex justify-end"},[_vm._v("\n            Service client\n            "),_c('img',{staticClass:"ml-1",staticStyle:{"width":"34px","height":"34px"},attrs:{"src":require("../../../assets/icons/interface-comment.svg?img"),"alt":""}})])}]

export { render, staticRenderFns }