import Vue from 'vue'
import dayjs from 'dayjs'
import LoadGoogleMapsApi from 'load-google-maps-api'
import storage from 'local-storage-fallback'
import Pusher from 'pusher-js'
import { v4 as uuid } from 'uuid'
import VWave from 'v-wave'
import SkyBtn from '../components/general/SkyBtn'
import SkyLightBtn from '../components/general/SkyLightBtn'
import SkyInput from '../components/general/SkyInput'
import SkyCheckbox from '../components/general/SkyCheckbox'

Vue.use(VWave)

Vue.component('SkyBtn', SkyBtn)
Vue.component('SkyLightBtn', SkyLightBtn)
Vue.component('SkyInput', SkyInput)
Vue.component('SkyCheckbox', SkyCheckbox)
const extend = (app, mixin) => {
  if (!app.mixins) {
    app.mixins = []
  }
  app.mixins.push(mixin)
}

const helpers = {
  makeUri (path, type = 'product') {
    return '/' + path.map(i => encodeURIComponent(i)).join('/') + (type === 'category' ? '/' : '')
  },
  formatCurrency (value) {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 2
    }).format(value)
  },
  formatDate (date) {
    return dayjs(date).format('DD/MM/YYYY')
  },
  makeCdnUrl (initialUrl, slug, type, size) {
    return `${process.env.NUXT_ENV_CDN_URL}/${initialUrl.slice(-3)}/${type}/${size}/${slug}.jpg`
  }
}

const eventListeners = (app, store) => {
  const onCheckout = (step, stepLabel) => {
    app.$gtm.push({ ecommerce: null })
    app.$gtm.push({
      event: 'ua-event',
      uaEvent: {
        category: 'Ecommerce',
        action: 'Checkout',
        label: stepLabel
      },
      ecommerce: {
        currencyCode: 'EUR',
        checkout: {
          actionField: { step },
          products: store.state.cart.cart.products.map((product) => {
            return {
              name: product.label,
              id: product.sku,
              price: product.price,
              // brand: 'Google',
              category: product.properties?.path?.[product.properties.path.length - 2],
              // variant: 'Gray',
              quantity: product.quantity
            }
          })
        }
      }
    })
    app.$gtm.push({ ecommerce: null, uaEvent: null })
  }
  const onCartEvent = (event) => {
    app.$gtm.push({
      event: 'ga-event',
      gaEvent: event,
      ecommerce: {
        shipping_tier: store.state.cart.cart.shipping?.shipping_service_name,
        value: store.getters['cart/productsTotal'],
        currency: 'EUR',
        items: store.state.cart.cart.products.map((product, index) => {
          return {
            item_id: product.sku,
            item_name: product.label,
            currency: 'EUR',
            index,
            item_category: product.properties?.path?.[product.properties.path.length - 2],
            price: product.price,
            quantity: product.quantity
          }
        })
      }
    })
    app.$gtm.push({ ecommerce: null, gaEvent: null })
  }
  return {
    onRegularPageView: () => {
      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'nuxtRoute'
      })
    },
    onProductDetailsView: (product) => {
      app.$gtm.push({ ecommerce: null }) // Clear the previous ecommerce object.
      app.$gtm.push({
        event: 'nuxtRoute',
        // uaEvent: {
        //   action: 'Product Details Impression',
        //   category: 'ECommerce',
        //   label: `#${product.sku} - ${product.label}`
        // },
        ecommerce: {
          detail: {
            products: [{
              name: product.label, // Name or ID is required.
              id: product.sku,
              price: product.pricing.unit_taxed_price,
              category: product.default_category?.slug
            }]
          }
        }
      })
      app.$gtm.push({ ecommerce: null, uaEvent: null })

      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'view_item',
        ecommerce: {
          items: [
            {
              item_id: product.sku,
              item_name: product.label,
              index: 0,
              item_category: product.path[product.path.length - 2],
              price: product.pricing?.unit_taxed_price,
              currency: 'EUR'
            }
          ]
        }
      })
      app.$gtm.push({ ecommerce: null, gaEvent: null })
    },
    onCategoryView: (products, listName, listId) => {
      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'nuxtRoute',
        // uaEvent: {
        //   action: 'ProductCard Impressions',
        //   category: 'ECommerce',
        //   label: `#${listName} - ${products.length} products`
        // },
        ecommerce: {
          currencyCode: 'EUR',
          impressions: [products.map((product, index) => {
            return {
              id: product.sku,
              name: product.label,
              list: listName,
              category: product.path[product.path.length - 2],
              position: index + 1
            }
          })]
        }
      })
      app.$gtm.push({ ecommerce: null, uaEvent: null })

      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'view_item_list',
        ecommerce: {
          items: products.slice(0, 10).map((h, hIndex) => { // TODO: Voir si on log les autres produits
            return {
              item_id: h.sku,
              item_name: h.label,
              index: hIndex,
              item_list_name: listName,
              item_list_id: listId,
              item_category: h.path[h.path.length - 2],
              price: h.pricing?.unit_taxed_price,
              currency: 'EUR'
            }
          }),
          item_list_name: listName,
          item_list_id: listId
        }
      })
      app.$gtm.push({ ecommerce: null, gaEvent: null })
    },
    onProductCardClick: (product, listName, position) => {
      app.$gtm.push({ ecommerce: null }) // Clear the previous ecommerce object.
      app.$gtm.push({
        ecommerce: {
          click: {
            actionField: { list: listName }, // Optional list property.
            products: [{
              name: product.label, // Name or ID is required.
              id: product.sku,
              price: product.pricing.unit_taxed_price,
              // 'brand': productObj.brand,
              category: product.path[product.path.length - 2],
              // 'variant': productObj.variant,
              position
            }]
          }
        }
      })
      app.$gtm.push({
        event: 'ua-event',
        uaEvent: {
          action: 'Product Click',
          category: 'Ecommerce',
          label: `#${product.sku} - ${product.label}`
        }
      })
      app.$gtm.push({ ecommerce: null, uaEvent: null })
      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'select_item',
        ecommerce: {
          items: [
            {
              item_id: product.sku,
              item_name: product.label,
              index: position - 1,
              item_category: product.path[product.path.length - 2],
              price: product.pricing?.unit_taxed_price,
              currency: 'EUR'
            }
          ]
        }
      })
      app.$gtm.push({ ecommerce: null, gaEvent: null })
    },
    onAddedToCart: (product, quantity, price, origin) => {
      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'ua-event',
        uaEvent: {
          category: 'Ecommerce',
          action: 'Add to Cart',
          label: origin || 'Origine inconnue'
        },
        ecommerce: {
          currencyCode: 'EUR',
          add: { // 'add' actionFieldObject measures.
            products: [{ //  adding a product to a shopping cart.
              name: product.label,
              id: product.sku,
              price: price || product.pricing.unit_taxed_price,
              // brand: 'Google',
              category: product.path[product.path.length - 2],
              // variant: 'Gray',
              quantity
            }]
          }
        }
      })
      app.$gtm.push({ ecommerce: null, uaEvent: null })

      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'add_to_cart',
        ecommerce: {
          items: [
            {
              item_id: product.sku,
              item_name: product.label,
              item_category: product.path[product.path.length - 2],
              price: price || product.pricing.unit_taxed_price,
              currency: 'EUR',
              quantity
            }
          ]
        }
      })
      app.$gtm.push({ ecommerce: null, gaEvent: null })
    },
    onRemovedFromCart (sku, origin) {
      const product = store.state.cart.cart.products.find((p) => {
        return p.sku === sku
      })
      if (!product) {
        return
      }
      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'ua-event',
        uaEvent: {
          category: 'Ecommerce',
          action: 'Remove From Cart',
          label: origin || 'Origine inconnue'
        },
        ecommerce: {
          currencyCode: 'EUR',
          remove: { // 'add' actionFieldObject measures.
            products: [{ //  adding a product to a shopping cart.
              name: product.label,
              id: product.sku,
              category: product.properties?.path?.[product.properties.path.length - 2],
              quantity: product.quantity,
              price: product.price

            }]
          }
        }
      })
      app.$gtm.push({ ecommerce: null, uaEvent: null })

      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'remove_from_cart',
        ecommerce: {
          items: [
            {
              item_id: product.sku,
              item_name: product.label,
              item_category: product.properties.path[product.properties.path.length - 2],
              price: product.price,
              currency: 'EUR',
              quantity: product.quantity
            }
          ]
        }
      })
      app.$gtm.push({ ecommerce: null, gaEvent: null })
    },
    onCheckout,
    onBeginCheckout () {
      onCheckout(2, 'Authentication')
      onCartEvent('begin_checkout')
    },
    onChoseShipping () {
      onCartEvent('add_shipping_info')
    },
    onChosePayment (paymentMethod) {
      onCartEvent('add_payment_info', {
        payment_type: paymentMethod
      })
    },
    onErrorPage: (errorCode, url, message) => {
      if (errorCode !== 404) {
        app.$sentry.captureException(new Error(`${errorCode}: ${message}`))
      }
      app.$gtm.push({ uaEvent: null })
      app.$gtm.push({
        event: 'ua-event',
        uaEvent: {
          action: errorCode,
          category: 'Error',
          label: `${url}${message || ''}`
        }
      })
      app.$gtm.push({ uaEvent: null })
      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'error_page',
        error: {
          code: errorCode,
          message: `${url}: ${message || ''}`
        }
      })
      app.$gtm.push({ gaEvent: null, error: null })
    },
    onRuntimeError: (error, description) => {
      if (app.context.isDev) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
      app.$sentry.captureException(error)
      app.$gtm.push({ ecommerce: null, uaEvent: null })
      app.$gtm.push({
        event: 'ua-event',
        uaEvent: {
          category: 'Runtime Error',
          action: description,
          label: error?.response?.status || 'runtime'
        }
      })
      app.$gtm.push({ ecommerce: null, uaEvent: null })
      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'error-runtime',
        error: {
          code: error?.response?.status || 'runtime',
          message: description
        }
      })
      app.$gtm.push({ gaEvent: null, error: null })
    },
    onTransaction: (transaction) => {
      app.$gtm.push({ ecommerce: null }) // Clear the previous ecommerce object.
      app.$gtm.push({
        event: 'nuxtRoute',
        // uaEvent: {
        //   category: 'Ecommerce',
        //   action: 'Checkout',
        //   label: 'Validation de commande'
        // },
        ecommerce: {
          purchase: {
            actionField: {
              id: transaction.properties.orderId, // Transaction ID. Required for purchases and refunds.
              // affiliation: 'Online Store',
              revenue: transaction.total, // Total transaction value (incl. tax and shipping)
              // tax: '4.90',
              shipping: transaction.shipping.price
              // coupon: 'SUMMER_SALE'
            },
            products: transaction.products.map((product) => {
              return {
                name: product.label,
                id: product.sku,
                price: product.price,
                category: product.properties?.path?.[product.properties.path.length - 2],
                quantity: product.quantity
              }
            })
          }
        }
      })
      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'purchase',
        ecommerce: {
          transaction_id: transaction.properties?.orderId || transaction.id,
          shipping_tier: transaction.shipping?.shipping_service_name,
          value: transaction.total - transaction.shipping.price,
          shipping: transaction.shipping.price,
          currency: 'EUR',
          items: transaction.products.map((product, index) => {
            return {
              item_id: product.sku,
              item_name: product.label,
              currency: 'EUR',
              index,
              item_category: product.properties?.path?.[product.properties.path.length - 2],
              price: product.price,
              quantity: product.quantity
            }
          })
        }
      })
      app.$gtm.push({ ecommerce: null, gaEvent: null })
    },
    onLogin: (userId) => {
      app.$gtm.push({ ecommerce: null, uaEvent: null, gaEvent: null })
      app.$gtm.push({
        user_id: userId
      })
    },
    onActivatedFilter: (category, { feature_name: featureName, value }) => {
      app.$gtm.push({ ecommerce: null, uaEvent: null })
      app.$gtm.push({
        event: 'ua-event',
        uaEvent: {
          category: 'Activated Filter',
          action: category.label,
          label: `${featureName} - ${value}`
        }
      })
      app.$gtm.push({ ecommerce: null, uaEvent: null })
      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'activated_filter',
        properties: {
          category_name: category.label,
          filter_name: featureName,
          filter_value: value
        }
      })
      app.$gtm.push({ ecommerce: null, gaEvent: null, properties: null })
    },
    onActivatedFacetGroup: (category, name) => {
      app.$gtm.push({ ecommerce: null })
      app.$gtm.push({
        event: 'ua-event',
        uaEvent: {
          category: 'Clicked on pre-filter',
          action: category.label,
          label: name
        }
      })
      app.$gtm.push({ ecommerce: null, uaEvent: null })
      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'activated_pre_filter',
        properties: {
          category_name: category.label,
          filter_name: name
        }
      })
      app.$gtm.push({ ecommerce: null, gaEvent: null, properties: null })
    },
    onActivatedVariant: (product, { feature_name: featureName, value }) => {
      app.$gtm.push({ ecommerce: null, uaEvent: null })
      app.$gtm.push({
        event: 'ua-event',
        uaEvent: {
          category: 'VariantClick',
          action: `${product.sku} - ${product.label}`,
          label: `${featureName} - ${value}`
        }
      })
      app.$gtm.push({ ecommerce: null, uaEvent: null })
      app.$gtm.push({
        event: 'ga-event',
        gaEvent: 'variant_click',
        properties: {
          category_name: `${product.sku} - ${product.label}`,
          filter_name: featureName,
          filter_value: value
        }
      })
      app.$gtm.push({ ecommerce: null, gaEvent: null, properties: null })
    }
  }
}

const chat = {
  close () {
    if (!window._chatlio) {
      return
    }
    window._chatlio.hide()
  },
  show () {
    if (!window._chatlio) {
      return
    }
    window._chatlio.show({
      expanded: false
    })
  }
}

export default ({ store, app, route, nuxtState, req }, inject) => {
  if (process.client) {
    const pusher = new Pusher('0dbca01b8e884fd13219', {
      cluster: 'eu'
    })
    const tabId = uuid()
    inject('tabId', tabId)
    inject('pusher', pusher)
    const gtmEvents = eventListeners(app, store)
    app.$api.interceptors.request.use(
      async (config) => {
        // Do something before request is sent
        Object.assign(config.headers, {
          'Pusher-Tab-Id': tabId
        })
        if (app.$fire.auth.currentUser) {
          const token = await app.$fire.auth.currentUser.getIdToken()
          if (token) {
            Object.assign(config.headers, {
              authorization: `Bearer ${token}`
            })
          }
        }

        return await config
      },
      error => Promise.reject(error)
    )
    extend(app, {
      async mounted () {
        try {
          const customToken = route.query.custom_token
          await store.dispatch('screen/monitorScreen')
          await store.dispatch('auth/init')
          if (customToken) {
            await this.$fire.auth.signInWithCustomToken(customToken)
            const { data: lastUserCart } = await app.$api.get('/me/cart')
            if (lastUserCart) {
              storage.setItem('yellaw-cart-id', lastUserCart.id)
            }
          }
          await store.dispatch('cart/init')
          if (store.state.auth.userId && store.state.cart.cart.firebase_user_id !== store.state.auth.userId) {
            await store.dispatch('cart/setFirebaseUserId', store.state.auth.userId)
          }
          app.$fire.auth.onAuthStateChanged(async (user) => {
            if (user) {
              store.commit('cart/setReady', false)
              gtmEvents.onLogin(user.uid)
              const { data: lastUserCart } = await app.$api.get('/me/cart')
              if (lastUserCart && !store.state.cart.cart?.products?.length) {
                storage.setItem('yellaw-cart-id', lastUserCart.id)
                await store.dispatch('cart/init')
              } else {
                await store.dispatch('cart/setFirebaseUserId', user.uid)
                store.commit('cart/setReady', true)
              }
              await store.dispatch('cart/fetchWishlists')
            }
          })
        } catch (error) {
          gtmEvents.onRuntimeError(error, 'skylantern/init-app')
        }
      },
      watch: {
        '$store.state.cart.show' (val) {
          if (val) {
            chat.close()
          } else {
            chat.show()
          }
        }
      }
    })
    inject('maps', () => {
      return LoadGoogleMapsApi({
        key: 'AIzaSyBNmh6j_I-2hDJDC2hIqAQ_WmV1CCWRKy0',
        libraries: ['places']
      })
    })
    inject('gtmEvents', gtmEvents)
    inject('chat', chat)
  } else {
    store.commit('ui/SET_HOST', process.env.APP_URL)
    store.commit('ui/SET_PAYPAL_CLIENT_ID', process.env.PAYPAL_CLIENT_ID)
  }

  inject('helpers', helpers)
  inject('host', store.state.ui.host)
}
