<template>
  <div class="sky__input" :class="{ small }">
    <div v-if="label" class="sky__input-label">
      {{ label }} <span v-if="required" class="text-red-500">*</span>
    </div>
    <input
      ref="input"
      v-bind="$attrs"
      :type="type"
      class="sky__input-input"
      :class="[error ? 'border border-red-700' : '', big ? 'big' : '']"
      v-on="listeners"
    >
    <div v-if="error" class="text-xs px-2 text-red-700">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      required: false,
      default: null,
      type: String
    },
    required: {
      required: false,
      default: false,
      type: Boolean
    },
    type: {
      required: false,
      default: 'text',
      type: String
    },
    error: {
      required: false,
      default: null,
      type: String
    },
    big: {
      required: false,
      default: false,
      type: Boolean
    },
    small: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  computed: {
    listeners () {
      return {
        ...this.$listeners,
        input: (e) => {
          this.$emit('input', e.target.value)
        }
      }
    }
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    },
    blur () {
      this.$refs.input.blur()
    }
  }
}
</script>

<style lang="scss" scoped>
.sky__input {
  .sky__input-label {
    @apply uppercase text-xs font-bold tracking-widest ml-2 mb-1;
  }
  .sky__input-input {
    @apply bg-sky_grey_darker block w-full px-4 py-2;
    &.big {
      @apply py-3;
    }
    &:focus {
      @apply outline-none;
    }
  }
  &.small {
    .sky__input-label {
      @apply uppercase text-xs font-bold tracking-widest ml-2 mb-1;
    }
    .sky__input-input {
      @apply text-xs px-2;
    }
  }
}
</style>
